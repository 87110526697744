<template>
  <div v-if="dialog.params.current_section_id">
    <router-view></router-view>

    <Lang/>

    <v-btn v-if="user.role.permissions.scenario.sections.scenario.canSave"
      elevation="23" id="save-button"
      fixed left bottom
      :loading="false"
      color="primary"
      @click="save"
      :fab="$vuetify.breakpoint.smAndDown"
    >
      <v-icon
        color="white"
        small
        :class="{ 'mr-2': $vuetify.breakpoint.mdAndUp } "
      >$iconsave</v-icon>
      <span v-if="$vuetify.breakpoint.mdAndUp">{{$t('modules.dialog.texts.settings.save')}}</span>
    </v-btn>
  </div>
</template>

<script>
import Lang from './common/Lang';
import { mapGetters } from 'vuex';
import Vue from 'vue';
import axios from 'axios/index';

export default {
  name: 'Dialog',

  components: {
    Lang,
  },

  computed: {
    ...mapGetters([
      'dialog',
      'engine_settings',
      'bot_settings',
      'current_tab_type',
      'all_bot_data_init',
      'user',
    ]),
  },

  mounted: function () {
    if (this.all_bot_data_init) {
      this.$store.dispatch('loadDialogData');
    }

    //Сохранение по нажатию ctrl + s
    if (this.dialog.params.save_listener_installed === false) {
      this.$store.commit('SET_DIALOG_PARAMS', {
        field: 'save_listener_installed',
        value: true,
      });
      window.addEventListener('keydown', function (event) {
        if (event.ctrlKey && event.code) {
          if (event.code === 'KeyS') {
            document.getElementById('save-button').click();
            event.preventDefault();
            return false;
          }
        }
      });
    }
  },

  methods: {
    save() {
      let url = this.engine_settings.active_url;
      let bot_id = parseInt(this.bot_settings.active_id);
      let request = this._.cloneDeep(this.dialog);
      request.bot_id = bot_id;
      request.updatedAt = this.bot_settings.updatedAt;
      let token = Vue.$cookies.get('token_e');

      if (url && bot_id && token) {
        this.$store.dispatch('updateAjaxDialog', [true, true]);
        axios
          .post(url + '/kw/dialog/save/', request, {
            headers: {
              'X-CSRF-TOKEN': token,
            },
          })
          .then(
            (response) => {
              this.$store.dispatch('updateAjaxDialog', [false, false]);
              if (
                response.data?.status === 'success' &&
                response.data.data?.updatedAt
              ) {
                this.$store.commit('SET_BOT_SETTING', {
                  field: 'updatedAt',
                  value: parseInt(response.data.data.updatedAt),
                });
              } else {
                this.$store.dispatch('updateAjaxDialog', [
                  true,
                  false,
                  this.$t('modules.dialog.texts.common.warning'),
                  this.$t('modules.dialog.texts.common.wrong_response_status'),
                ]);
              }
            },
            (err) => {
              let title = this.$t('common.error');
              let description = this.$t(
                'modules.dialog.texts.common.cant_save_dialog'
              );
              if (err.response.data.message === 'bot_last_update_error') {
                title = this.$t('modules.dialog.texts.common.cant_save_dialog');
                description = this.$t(
                  'modules.dialog.texts.common.another_user_save_dialog'
                );
              }

              this.$store.dispatch('updateAjaxDialogError', [
                err,
                title,
                description,
              ]);
            }
          );
      }
    },
  },

  watch: {
    //Изменение языка
    all_bot_data_init: {
      handler: function () {
        if (this.all_bot_data_init && this.all_bot_data_init.ready) {
          this.$store.dispatch('loadDialogData');
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
#save-button {
  left: 80px;
  min-width: 190px;

  @media screen and (max-width: 959px) {
    min-width: auto;
    max-width: 40px;
    max-height: 40px;

    left: 15px;
  }
}
</style>
