import Vue from 'vue';
import VueI18n from 'vue-i18n';
// import _ from 'lodash';
import merge from 'lodash/merge';
import { extra_modules_list } from '../modules/custom_modules_list';

Vue.use(VueI18n);

//todo можно вынести в env
const langs = ['ru', 'en', 'uk', 'de'];

//Определение языка из куки
const matches = document.cookie.match(new RegExp('(?:^|; )user_lang=([^;]*)'));

//Определение языка
const user_lang = matches ? decodeURIComponent(matches[1]) : undefined;
const config_lang_code = user_lang && langs.includes(user_lang) ? user_lang : 'ru';

let lang_file = {};

for (let lang of langs) {
  const lang_obj = require('./lang_' + lang + '.json');
  // lang_file = _.merge(lang_file, lang_obj);
  lang_file = merge(lang_file, lang_obj);
}

// Подключение языковых файлов модулей из модулей
const connectModuleLangs = (module) => {

  for (let lang of langs) {
    try {
      const lang_obj = require(`@/modules/${module.component_name}/lang/lang_${lang}.json`);
      // lang_file = _.merge(lang_file, lang_obj);
      lang_file = merge(lang_file, lang_obj);
    } catch (e) {
      console.log('Not found module lang file', module.component_name);
    }
  }
};

extra_modules_list
  .filter((module) => module && module.active && module.component_name)
  .forEach(connectModuleLangs);

const i18n = new VueI18n({
  locale: config_lang_code,
  fallbackLocale: 'ru',
  messages: lang_file,
});

// для перевода объектов по ключу
VueI18n.prototype.$tr = function (item, attribute) {
  if (!item) return '';

  return item[attribute + '_' + i18n.locale] ?? '';
};

export default i18n;
