<template>

        <v-btn
                fixed
                left
                bottom
                :loading="false"
                color="white"
                @click="changeLang"
                id="lang-button"

                :rounded="$vuetify.breakpoint.smAndDown"
        >
            <span style="color:#050038">{{current_lang_code}}</span>
        </v-btn>

</template>

<script>
    import {mapGetters, mapActions} from 'vuex'

    export default {
        name: 'Lang',

        components: {
        },

        data: () => ({

        }),

        computed: {
            ...mapGetters([
                'languages',
                'languages_obj',
                'current_lang_code',
            ]),

        },

        methods: {

            //Определяет следующий по списку язык и переключает на него
             changeLang: async function(){
                let next_lang_code = null

                let find_current = false
                for(let lang_code in this.languages_obj){
                    if (lang_code === this.current_lang_code){
                        find_current = true
                    }else if(find_current){
                        next_lang_code = lang_code
                        break
                    }
                }

                if(!next_lang_code && this.languages[0] && this.languages[0].code){
                    next_lang_code = this.languages[0].code
                }

                await this.$store.dispatch('setCurrentLangCode', next_lang_code)
                await this.$store.dispatch('loadDialogData');
            }
        },

    };
</script>

<style lang="scss">
    #lang-button{
        @media screen and (max-width: 959px){
            min-width: 40px;
            width: 40px;
            height: 40px;
            bottom: 70px;
        }

        @media screen and (min-width: 960px){
            width: 45px;
            min-width: 45px !important;

            &.v-btn.v-size--default{
                font-size: 14px;
                box-shadow: 0px 5px 15px rgba(77, 76, 172, 0.12);
                border-radius: 5px;
            }
        }

    }
</style>
