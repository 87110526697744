import LAYOUT from '@/layout/layout';
import moduleLayout from '@/modules/Statistics/layout/Layout.vue';

export default [
  {
    path: '/statistics',
    component: LAYOUT.base,
    redirect: '/statistics/funnels',
    children: [
      {
        path: 'funnels',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'StatisticsFunnels',
            component: () =>
              import(
                /* webpackChunkName: 'statistics-funnels' */ '@/modules/Statistics/views/statistics/FunnelsTab.vue'
              ),
          },
        ],
      },
      {
        path: 'operators',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'StatisticsOperators',
            component: () =>
              import(
                /* webpackChunkName: 'statistics-operators' */ '@/modules/Statistics/views/statistics/OperatorsTab.vue'
              ),
          },
        ],
      },
      {
        path: 'sections',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'StatisticsSections',
            component: () =>
              import(
                /* webpackChunkName: 'statistics-sections' */ '@/modules/Statistics/views/statistics/SectionsTab.vue'
              ),
          },
        ],
      },
      {
        path: 'tables',
        redirect: { name: 'StatisticsTablesOperators' },
        component: () =>
          import(
            /* webpackChunkName: 'statistics-operators' */ '@/modules/Statistics/views/statistics/TablesTab.vue'
          ),
        children: [
          {
            path: 'operators',
            component: moduleLayout,
            children: [
              {
                path: '',
                name: 'StatisticsTablesOperators',
                component: () =>
                  import(
                    /* webpackChunkName: 'statistics-operators' */ '@/modules/Statistics/views/statistics/tables/OperatorsTab.vue'
                  ),
              },
            ],
          },
          {
            path: 'statuses',
            component: moduleLayout,
            children: [
              {
                path: '',
                name: 'StatisticsTablesStatuses',
                component: () =>
                  import(
                    /* webpackChunkName: 'statistics-statuses' */ '@/modules/Statistics/views/statistics/tables/StatusesTab.vue'
                  ),
              },
            ],
          },
          {
            path: 'subjects',
            component: moduleLayout,
            children: [
              {
                path: '',
                name: 'StatisticsTablesSubjects',
                component: () =>
                  import(
                    /* webpackChunkName: 'statistics-operators' */ '@/modules/Statistics/views/statistics/tables/SubjectsTab.vue'
                  ),
              },
            ],
          },
          {
            path: 'dialogs',
            component: moduleLayout,
            children: [
              {
                path: '',
                name: 'StatisticsTablesDialogs',
                component: () =>
                  import(
                    /* webpackChunkName: 'statistics-operators' */ '@/modules/Statistics/views/statistics/tables/DialogsTab.vue'
                  ),
              },
            ],
          },
          {
            path: 'skillgroups',
            component: moduleLayout,
            children: [
              {
                path: '',
                name: 'StatisticsTablesSkillgroups',
                component: () =>
                  import(
                    /* webpackChunkName: 'statistics-operators' */ '@/modules/Statistics/views/statistics/tables/SkillgroupsTab.vue'
                  ),
              },
            ],
          },
          {
            path: 'nps',
            component: moduleLayout,
            children: [
              {
                path: '',
                name: 'StatisticsTablesNps',
                component: () =>
                  import(
                    /* webpackChunkName: 'statistics-operators' */ '@/modules/Statistics/views/statistics/tables/NpsTab.vue'
                  ),
              },
            ],
          },
          {
            path: 'quality-operators',
            component: moduleLayout,
            children: [
              {
                path: '',
                name: 'QualityOperatorsTablesNps',
                component: () =>
                    import(
                        /* webpackChunkName: 'statistics-operators' */ '@/modules/Statistics/views/statistics/tables/QualityOperatorsTab.vue'
                        ),
              },
            ],
          },
          {
            path: 'quality-skillgroups',
            component: moduleLayout,
            children: [
              {
                path: '',
                name: 'QualitySkillgroupsTablesNps',
                component: () =>
                    import(
                        /* webpackChunkName: 'statistics-operators' */ '@/modules/Statistics/views/statistics/tables/QualitySkillgroupsTab.vue'
                        ),
              },
            ],
          },

        ],
      },
    ],
  },
];
