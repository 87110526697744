<template>
  <v-container grid-list-md>
    <v-card
      v-if="custom_data_tables.length === 0 && modulesCheck"
      max-width="600"
      class="modules-list-empty"
    >
      <v-card-title class="justify-center">
        {{$t('modules.op.texts.empty_modules_tables_title')}}
      </v-card-title>
        {{$t('modules.op.texts.empty_modules_tables_text')}}
      <v-card-text>
      </v-card-text>
    </v-card>


    <v-card 
      elevation="0" 
      outlined
      v-else
    >
      <v-card-title class="justify-center">
            <v-tabs centered>
              <v-tab v-for="( item, i ) in custom_data_tables" :key="i" @click="loadData(item)">{{item.name}}</v-tab>
            </v-tabs>

      </v-card-title>
      <v-card-text>
        <p class="model-description">{{model_description}}</p>

        <v-layout wrap>
          <CRUD
              v-if="load"
              :entity="entity"
              :multiple_entity="multiple_entity"
              :entity_fields="entity_fields"
          />
        </v-layout>

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import customDataMixin from './customDataMixin';
import {mapActions, mapGetters} from "vuex";
import Vue from "vue";
import axios from "axios";
import CRUD from "@/modules/CustomData/components/CRUD";
import i18n from "@/lang/i18n";

export default {
  name: "CustomDataTab",

  mixins: [customDataMixin],

  components: {
    CRUD,


  },

  data: () => ({
    activeTable: {},
    tables: [],
    load: false,
    entity: null,
    multiple_entity: null,
    entity_fields: [],
    model_description:null,
    model_json:null,

    /**FILTERS*/
    columns: [{

    }],
		selectedColumns: [],
		filters: [{
      name:"color",
      type:"input"
    }],
		rows: [],
		filter: {},
    modulesCheck: false,

  }),

  async mounted() {
    await this.CustomDataAxiosGetTables();
    if(this.custom_data_tables)
    {
      this.modulesCheck = true
      this.multiple_entity = this.custom_data_tables[0].name
      this.model_description = this.custom_data_tables[0].description
      this.model_json = this.custom_data_tables[0].columns_json
      this.entity_fields = this.custom_data_tables[0].columns_json
      this.load = true;
    }
    this.entity = "section";
  },

  watch: {

  },

  computed: {
    ...mapGetters([
      'dialog',
      'engine_settings',
      'bot_settings',
      'current_tab_type',
      'all_bot_data_init',
      'user',
      'custom_data_tables'
    ]),
  },

  methods: {
     ...mapActions(['CustomDataAxiosGetTables','CustomDataAxiosClearModel']),

    async loadData(item) {
      this.load = false;
      this.multiple_entity = item.name
      this.model_description = item.description
      this.entity_fields = item.columns_json
      this.load = true;
    },

    setFilter(filter) {
			this.filter = filter;
      console.log('setFilter', filter)

			///this.request();
		},
  },
};
</script>



<style lang="scss">
.model-description {
  font-size: 12px;
  text-align: center;
}

.json-example {
  font-size: 12px;
  color:#050038;
  background-color: #FFFBD1;
  padding: 20px;
}
.modules-list-empty{
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
  text-align: center;
}
</style>
