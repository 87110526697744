import HELPERS from '@/modules/OperatorPanel/helpers';
import Vue from 'vue';
import axios from '../../axios-middleware';
import i18n from '@/lang/i18n.js';
import kwizbotModuleSettings from '@/modules/OperatorPanel/kwizbot_settings.js';
import { v4 as uuid_v4 } from 'uuid';

const options = {
  // second: "2-digit",
  // minute: "2-digit",
  // hour: "2-digit",
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};
const INTL = new Intl.DateTimeFormat('ru-RU', options);

const state = {
  operator_panel: {
    chatRooms: [],
    notification: false,
    activeChatGroup: null,
    activeChatRoom: null,
    serverTime: null,
    subjectList: [],
    subjectListTree: [],
    rebuildKey: Date.now(),

    chatRoomGroups: [],
    typeChatRooms: 'all',
    groupByTheme: false,
    closedRoomsByClients: [],
  },
};

const mutations = {
  OP_SET_CHAT_ROOMS(state, chatRooms) {
    Vue.set(state.operator_panel, 'chatRooms', chatRooms);
  },

  OP_SET_ACTIVE_CHAT_GROUP(state, chatGroupId) {
    Vue.set(state.operator_panel, 'activeChatGroup', chatGroupId);
  },

  OP_SET_ACTIVE_CHAT_ROOM(state, chatRoomId) {
    Vue.set(state.operator_panel, 'activeChatRoom', chatRoomId);
  },

  OP_SET_CHAT_ROOM_PARTICIPANTS(state, chatRoom) {
    const participants = HELPERS.formatDialogParticipants(chatRoom);
    Vue.set(state.operator_panel.active_chat, 'participants', participants);
  },

  OP_SET_SERVER_TIME(state, dateStr) {
    state.operator_panel.serverTime = dateStr;
  },

  OP_SET_SUBJECT_LIST(state, subjectList) {
    state.operator_panel.subjectList = subjectList;
  },

  OP_SET_SUBJECT_LIST_TREE(state, subjectList) {
    state.operator_panel.subjectListTree = subjectList;
  },

  OP_SET_ACTIVE_CHAT_ROOM_URL(state, chatRoomId) {
    const menuItem = kwizbotModuleSettings.module_settings.menu_items[0];

    let url = `/${menuItem.route}/chats`;

    if (chatRoomId) {
      url += `?chat_room_id=${chatRoomId}`;
    }

    window.history.pushState(menuItem.title, 'title', url);
  },

  OP_SET_REBUILD_KEY(state, rebuildKey) {
    state.operator_panel.rebuildKey = rebuildKey;
  },

  OP_SET_CHAT_NOTINICATION(state, message) {
    console.log(message, 'OP_SET_CHAT_NOTINICATION')
    const { chatRooms } = state.operator_panel;
    const room = chatRooms.find((v) => v.id === message?.data?.chat_room_id);

    state.operator_panel.notification = true;

    console.log(room, 'room')

    if (room) {
      const activeMessage = room.messages.find((v) => v.id === message.data.id);

      if (activeMessage) {
        activeMessage.message_read = message.data.message_read;
      } else {
        room.messages.push(message.data);
      }
    }
  },

  OP_SET_CHAT_READ(state, chatId) {
    const { chatRooms } = state.operator_panel;
    const room = chatRooms.find((v) => v.id === chatId);

    state.operator_panel.notification = false;

    if (room) {
      room.messages.forEach((r) => (r.message_read = true));
    }
  },

  OP_SET_TYPE_CHAT_ROOMS(state, typeChatRoomsVal) {
    state.operator_panel.typeChatRooms = typeChatRoomsVal
  },

  OP_SET_GROUP_BY_THEME(state, groupByTheme) {
    state.operator_panel.groupByTheme = groupByTheme
  },

  OP_SET_CHAT_ROOM_GROUPS(state, chatRoomGroups) {
    state.operator_panel.chatRoomGroups = chatRoomGroups
  },

  OP_SET_CLOSED_ROOMS_BY_CLIENTS(state, payload) {
    state.operator_panel.closedRoomsByClients = payload
  }
};

const actions = {
  opSetChatNotification({ commit }, data) {
    commit('OP_SET_CHAT_NOTINICATION', data);
  },

  opSetChatReed({ commit }, data) {
    commit('OP_SET_CHAT_READ', data);
  },

  async OpAxiosGetChatRoomsRoot({ commit }, query) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/list/`;

    const params = query ? { params: query } : null;

    const response = await axios.get(url, params).catch((err) => {
      HELPERS.handleError([
        err,
        i18n.t('common.error'),
        i18n.t('common.error_get'),
      ]);
      return null;
    });

    return response;
  },

  async OpAxiosGetChatRooms({ commit, dispatch }) {
    const response = await dispatch('OpAxiosGetChatRoomsRoot');

    if (response) {
      const serverTime = response.data?.data?.current_datetime || null;
      const chatRooms = response.data?.data?.chat_rooms || [];

      commit('OP_SET_SERVER_TIME', serverTime);
      commit('OP_SET_CHAT_ROOMS', chatRooms);
    } else {
      commit('OP_SET_CHAT_ROOMS', []);
    }
  },

  /**
   * Create new chat room
   * @method OpAxiosCreateChatRoom
   * @param chatRoomTitle
   *
   */
  async OpAxiosCreateChatRoom(
    { state, commit, dispatch },
    { chatRoomTitle, number, channel, clientId, type }
  ) {
    if (number) number = number.replace(/[()\s\-.]/g, '');
    else number = '';

    let chat_room_request = {
      kw_channel: channel || 'kwizbot',
      kw_chat_id: number || uuid_v4(),
      subject_id: null,
      bot_id: state?.bot_settings?.active_id,
      kw_chat_title: chatRoomTitle || null,
      type: type || null,
    };

    console.log('chat_room_request', chat_room_request);

    /** СОЗДАЕМ НОВУЮ КОМНАТУ **/

    let url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/create/`;

    const chat_room_id = await axios
      .post(url, chat_room_request)
      .then((response) => {
        if (
          response.data &&
          response.data.data &&
          response.data.data.chat_room_id
        ) {
          return response.data.data.chat_room_id;
        } else return 0;
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t('common.error'),
          i18n.t('common.error_modify'),
        ]);
        return false;
      });

    if (!chat_room_id) {
      return 0;
    }

    //console.log("chat room id:" + chat_room_id)

    /**создаем OP client хотя он по факту не нужен **/

    /* const client_create_request = {
        "alias": chat_room_request.kw_channel + '_' + chat_room_request.kw_chat_id,
        "kw_channel": chat_room_request.kw_channel,
        "kw_chat_id": chat_room_request.kw_chat_id,
        //"full_name":chatRoomTitle,
        // "extra_data": {is_group: true}

    }

    url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/client/get_or_create/`;

    const client_id = await axios
      .post(url, chat_room_request)
      .then((response) => {

        if(response.data && response.data.data && response.data.data.client && response.data.data.client.id){
            return response.data.data.client.id

        } else return 0;

      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t('common.error'), i18n.t('common.error_modify')]);
        return false;
      });

    if(!client_id){
        return 0
    }*/

    const currentUserId = state.operator_panel.userInfo.id;

    /**ПОДКЛЮЧАЕМ ОПЕРАТОРА и псевдоклиента В ЧАТ СРАЗУ**/

    //console.log(currentUserId)
    let participants_add_request = {
      chat_room_id,
      participants: [
        {
          type: 'user',
          user_id: currentUserId,
        },
        /* {
                "type": "client",
                "user_id":  client_id
            }*/
      ],
    };

    if (clientId) {
      participants_add_request.participants.push({
        type: 'client',
        client_id: clientId,
      });
    }

    url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/participants/add/`;

    await axios.post(url, participants_add_request).catch((err) => {
      HELPERS.handleError([
        err,
        i18n.t('common.error'),
        i18n.t('common.error_modify'),
      ]);
      return false;
    });

    return chat_room_id;
  },

  async opGetSudjectList({ commit }) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/subjects/list/`;

    const subjects = await axios
      .get(url)
      .then((response) => {
        return response.data?.data?.subjects || [];
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t('common.error'),
          i18n.t('common.error_get'),
        ]);
        return [];
      });

    commit('OP_SET_SUBJECT_LIST', subjects);
  },

  async opGetSudjectListTree({ commit }) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/subjects/listTree/`;

    const subjects = await axios
      .get(url)
      .then((response) => {
        return response.data?.data?.subjects || [];
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t('common.error'),
          i18n.t('common.error_get'),
        ]);
        return [];
      });

    commit('OP_SET_SUBJECT_LIST_TREE', subjects);
  },

  async opAxiosChangeChatRoomTheme({ dispatch }, query) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/subject/change/`;

    const { roomId, subjectId } = query;

    const request = {
      chat_room_id: roomId,
      subject_id: subjectId,
    };

    const success = await axios
      .post(url, request)
      .then((response) => {
        if (response.data?.status === 'success') dispatch('OpAxiosGetChatRooms')
        return response.data?.status === 'success';
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t('common.error'),
          i18n.t('common.error_modify'),
        ]);
        return false;
      });

    return success;
  },

  opSetActiveChatGroup({ commit }, chatGroupId) {
    commit('OP_SET_ACTIVE_CHAT_GROUP', chatGroupId);
  },

  opSetActiveChatRoom({ commit, getters }, chatRoomId) {
    commit('OP_SET_ACTIVE_CHAT_ROOM', chatRoomId);

    const activeChatRoom = getters.opActiveChatRoom;

    commit('OP_SET_CHAT_ROOM_PARTICIPANTS', activeChatRoom);

    commit(
      'OP_SET_ACTIVE_CHAT_ROOM_URL',
      chatRoomId || activeChatRoom?.id || null
    );

    commit('OP_SET_CHAT_READ', chatRoomId || activeChatRoom?.id || null);
  },

  opSetTypeChatRooms ( {commit}, typeChatRoomsVal ) {
    commit('OP_SET_TYPE_CHAT_ROOMS', typeChatRoomsVal)
  },

  opSetGroupByTheme ( {commit}, groupByTheme ) {
    commit('OP_SET_GROUP_BY_THEME', groupByTheme)
  },

  opSetChatRoomGroups ( {state, commit} ) {
    const { chatRooms, subjectList } = state.operator_panel;

    const subjectObj = {
      alias: null,
      id: 0,
      name_en: 'Without theme',
      name_ru: i18n.t('modules.op.texts.without_subject'),
      name_uk: i18n.t('modules.op.texts.without_subject'),
      parent_id: null,
      dialogs: [],
      childSubjectIds: [0],
    };

    //sort ChatRooms
    const chatRoomsWithoutSubject = [];
    const chatRoomsWithSubject = [];

    const sortChatRooms = (chatRoom) => {
      chatRoom.subject_id === null
        ? chatRoomsWithoutSubject.push(chatRoom)
        : chatRoomsWithSubject.push(chatRoom);
    };

    chatRooms.forEach(sortChatRooms);

    if (chatRoomsWithoutSubject.length) {
      chatRoomsWithoutSubject.forEach(function (chatRoom) {
        subjectObj.dialogs.push(chatRoom)
      })
    }

    let subjectListWithDialogs = []

    if (chatRoomsWithSubject.length) {
      for (let subject of subjectList) {
        subject['dialogs'] = chatRoomsWithSubject.filter( ({subject_id}) => subject.id === subject_id  )
      }
      subjectListWithDialogs = subjectList.filter( ({dialogs}) => dialogs.length > 0 )
    }

    const chatRoomGroups = [subjectObj, ...subjectListWithDialogs]

    console.log(chatRoomGroups, 'opSetChatRoomGroups')
    commit('OP_SET_CHAT_ROOM_GROUPS', chatRoomGroups)
  },

  opAxiosClosedRoomsByClients ({commit}, query) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/closed_rooms_by_clients/`;

    const params = query ? { params: query } : null;

    return axios
      .get(url, params)
      .then((response) => {
        return response.data?.data?.chat_rooms || [];
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t('common.error'),
          i18n.t('common.error_get'),
        ]);
        return [];
      });
  },
};

const getters = {
  opIsNotification: (state) => {
    const { notification } = state.operator_panel;

    return notification;
  },

  opChatRooms: (state) => {
    const { chatRooms } = state.operator_panel;

    const addDate = function addFormatDate(room) {
      const date = new Date(room.createdAt);
      const formatDate = INTL.format(date);

      room.date = formatDate;
    };

    chatRooms.forEach(addDate);

    return chatRooms;
  },

  opChatRoomGroups2: (state) => {
    const { chatRooms, subjectList } = state.operator_panel;

    let chatRoomGroups = [];

    const subjectObj = {
        alias: null,
        id: 0,
        name_en: 'Without theme',
        name_ru: i18n.t('modules.op.texts.without_subject'),
        name_uk: i18n.t('modules.op.texts.without_subject'),
        parent_id: null,
        dialogs: [],
        childSubjectIds: [0],
    };

    //sort ChatRooms
    const chatRoomsWithoutSubject = [];
    const chatRoomsWithSubject = [];

    const sortChatRooms = (chatRoom) => {
      chatRoom.subject_id === null
        ? chatRoomsWithoutSubject.push(chatRoom)
        : chatRoomsWithSubject.push(chatRoom);
    };

    chatRooms.forEach(sortChatRooms);

    if (chatRoomsWithoutSubject.length) {
      chatRoomsWithoutSubject.forEach(function (chatRoom) {
        subjectObj.dialogs.push(chatRoom)
      })
      chatRoomGroups.push(subjectObj)
    }

    if (chatRoomsWithSubject.length) {

      chatRoomsWithSubject.forEach(function (chatRoom) {
        for (let i in subjectList) {
          if (subjectList[i].id === chatRoom.subject_id) {
            if (subjectList[i]?.dialogs === undefined) subjectList[i]['dialogs'] = []
            subjectList[i]?.dialogs.push(chatRoom)
            chatRoomGroups.push( subjectList[i] )
          }
        }
      })
    }

    console.log(chatRoomGroups, 'chatRoomGroups')

    return chatRoomGroups
  },

  opChatRoomsWithGroup: (state) => {
    const { chatRooms, subjectList } = state.operator_panel;
    const chatRoomsWithSubjects = chatRooms.filter( ({subject_id}) => subject_id != null )

    for (let subject of subjectList) {
      subject['dialogs'] = chatRoomsWithSubjects.filter( ({subject_id}) => subject.id === subject_id )
    }

    return subjectList.filter( (subject) => subject.dialogs.length > 0)
  },

  opChatRoomsWithoutGroup (state) {
    const { chatRooms } = state.operator_panel;
    return  chatRooms.filter( ({subject_id}) => subject_id === null )
  },

  opActiveChatGroup: (state, getters) => {
    const activeChatGroupId = state.operator_panel.activeChatGroup;

    const isEqualId = (group) => group.id === activeChatGroupId;

    // const activeGroup = getters.opChatRoomGroups.find(isEqualId);
    const activeGroup = state.operator_panel.chatRoomGroups.find(isEqualId);

    return activeGroup || null;
  },

  opActiveChatRoom: (state) => {
    const { chatRooms } = state.operator_panel;

    const activeChatRoomId = state.operator_panel.activeChatRoom;

    if (activeChatRoomId === null) {
      return null;
    }

    const isEqualId = (room) => room.id === activeChatRoomId;

    const activeChatRoom = chatRooms.find(isEqualId);

    return activeChatRoom || null;
  },

  opActiveChatRoomFromState: (state) => state.operator_panel.activeChatRoom,

  opServerTime: (state) => state.operator_panel.serverTime,

  opSubjectList: (state) => state.operator_panel.subjectList,

  opSubjectListTree: (state) => state.operator_panel.subjectListTree,

  opRebuildKey: (state) => state.operator_panel.rebuildKey,

  opTypeChatRooms: (state) => state.operator_panel.typeChatRooms,

  opGroupByTheme: (state) => state.operator_panel.groupByTheme,

  opChatRoomGroupsNew: (state) => state.operator_panel.chatRoomGroups,

  opClosedRoomsByClients: (state) => state.operator_panel.closedRoomsByClients
};

export default {
  state,
  mutations,
  actions,
  getters,
};
