/**
 * Created by Sergii Kravtsov 12.06.22
 */

const getTranslateFunc = function () {
  let i18n;

  try {
    i18n = require('@/lang/i18n.js').default;
  } catch (error) {
    // console.log(error);
  }

  return i18n
    ? (translateAlias) => i18n.t(translateAlias)
    : (translateAlias) => translateAlias;
};

const getTranslate = getTranslateFunc();

//Должен называться так же как и в modules_components_obj
let module_name = 'ActionJail';

let module_settings = {
  module_name,
};

//Корневой урл модуля
let module_alias = 'action_jail';

//Список разрешений модуля
module_settings.permits = {
  //Отображение пунктов меню
  filemanager_show_menu_item: {
    description: 'Отображать в меню пункт Action Jail',
  },
  filemanager_show_tab_item: {
    description: 'Отображать таб Action Jail',
  },
};

//Пункты в меню
module_settings.menu_items = [
  {
    title: getTranslate('modules.action_jail.module_settings.action_jail'),
    module: module_name,
    alias: module_alias,
    icon: 'mdi-language-javascript',
    permits: { show: 'action_jail_show_menu_item' },
    show: true,
    route: 'action_jail',
  },
];

//Табы модуля
module_settings.tabs_items = {};

module_settings.tabs_items[module_name] = {
  use_tabs: true,
  tabs_items: [
    {
      tab: getTranslate('modules.action_jail.module_settings.actions'),
      content: '',
      show: true,
      type: 'actions',
      alias: '/',
      permits: { show: 'action_jail_show_tab_item' },
      route: 'files',
    },
  ],
};

//Подключенные компоненты
module_settings.components = [
  {
    name: module_name,
    path: 'Component/tabs/ActionsTab.vue',
  },
];

//Экспорт для vue и для node
module.exports = { module_settings };
module.exports.default = { module_settings };
