export default [
  {
    path: '*',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: 'page-login' */ '@/views/Auth/Login.vue'),
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import(/* webpackChunkName: 'page-registration' */ '@/views/Auth/Registration.vue'),
  },
];
