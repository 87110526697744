import axios from "../../axios-middleware";
import i18n from "@/lang/i18n.js";
import HELPERS from "@/modules/OperatorPanel/helpers";

const state = {
  operator_panel: {
    chatRoomClient: null,
    clientHistory: [],
  },
};

const mutations = {
  OP_SET_CHAT_ROOM_CLIENT(state, clientInfo) {
    state.operator_panel.chatRoomClient = clientInfo;
  },

  OP_SET_CLIENT_HISTORY(state, history) {
    state.operator_panel.clientHistory = history;
  },
};

const actions = {
  async opGetClientInfo({ commit }, clientId) {
    if (!clientId) {
      commit("OP_SET_CHAT_ROOM_CLIENT", null);
      return;
    }

    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/client/${clientId}/info/`;

    const clientInfo = await axios
      .get(url)
      .then((response) => {
        return response.data?.data?.client || null;
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t('common.error'), i18n.t('common.error_get') + " opGetClientInfo"]);
        return null;
      });

    commit("OP_SET_CHAT_ROOM_CLIENT", clientInfo);
  },

  async opGetClientHistory({ commit }, clientId) {
    if (!clientId) {
      commit("OP_SET_CHAT_ROOM_CLIENT", null);
      commit("OP_SET_CLIENT_HISTORY", []);
      return;
    }

    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/client/${clientId}/chat_rooms_history/`;

    const history = await axios
      .get(url)
      .then((response) => {
        return response.data?.data?.chat_rooms_ids || [];
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t('common.error'), i18n.t('common.error_get') + " opGetClientHistory"]);
        return null;
      });

    commit("OP_SET_CLIENT_HISTORY", history);
  },

  async opGetHistoryItem(_context, chatRoomId) {
    if (!chatRoomId) return [];

    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/${chatRoomId}/messages/list?archive=1`;

    const chatRoomInfo = await axios
      .get(url)
      .then((response) => {
        const messages = response.data?.data?.messages || [];
        const participants = response.data?.data?.participants || [];

        return { messages, participants };
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t('common.error'), i18n.t('common.error_get')]);
        return { messages: [], participants: [] };
      });

    return chatRoomInfo;
  },
  /**
   * Create client request
   * @param {Object} params - Request params
   * @returns {Boolean} - Success status
   */
  async opCreateClient(_context, params) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/create_update_reguser/`;

    const success = await axios({
      url,
      method: "POST",
      data: params,
    })
      .then((response) => {
        return response.data?.status === "success";
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t("common.error"),
          i18n.t("common.error_get"),
        ]);

        return false;
      });

    return success;
  },

  async opBlockClient(_context, params) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/ban_reguser/`;

    const success = await axios({
      url,
      method: "POST",
      data: params,
    })
      .then((response) => {
        return response.data?.status === "success";
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t("common.error"),
          i18n.t("common.error_get"),
        ]);

        return false;
      });

    return success;
  },
};

const getters = {
  opChatRoomClient: (state) => state.operator_panel.chatRoomClient,

  opClientHistory: (state, getters) => {
    const rawHistory = state.operator_panel.clientHistory;
    const getRoom = (item) => item.chat_room;
    // const history = rawHistory.map(getRoom);
    const history = rawHistory;

    const subjects = getters.opSubjectList;
    const users = getters.opAllUsersList;

    const options = { history, subjects, users };
    const normHistory = HELPERS.rebuildChatRooms(options);

    return normHistory;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
