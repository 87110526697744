import Vue from "vue";
import axios from "axios";
import i18n from "@/lang/i18n.js";

const state = {
  custom_data:{
    tables:[]
  }
};

const mutations = {
    CUSTOM_DATA_SET_TABLES(state, tables) {
    state.custom_data.tables = tables;
  }
};

const actions = {

    /**
     * Get custom data tables
     * @method CustomDataAxiosGetTables
     */
   async CustomDataAxiosGetTables({ commit, dispatch, state }) {
      let success = false;
      const token = Vue.$cookies.get('token_e')
      const engine_url = state.engine_settings.active_url;
      const bot_id = state.bot_settings.active_id;
      const url = `${engine_url}/kw/custom_modules/list`
      const params = { bot_id }

      //dispatch("updateAjaxDialog", [true, true]);
      await axios.get(url, {
        headers: {
          "X-CSRF-TOKEN": token
        },
        params
      })
          .then( async (response) => {
                //dispatch("updateAjaxDialog", [false, false]);

                if ( response?.data?.status === 'success' && response.data?.data ) {
                  commit('CUSTOM_DATA_SET_TABLES',response.data.data)
                  success = true;
                }
              },
              (err) => {

              dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_get") ]);
              }
          );

      return success;
    },

    /**
     * Create custom data model
     * @method CustomDataAxiosCreateModel
     * @param name - model name
     * @param description  - model description
     * @param columns_json - model columns
     */
     async CustomDataAxiosCreateModel({ commit, dispatch, state }, {name, description, columns_json}) {

      let success = false;

      const token = Vue.$cookies.get('token_e')
      const engine_url = state.engine_settings.active_url;
      const bot_id = state.bot_settings.active_id;
      const user_id = state.user.id;

      const url = `${engine_url}/kw/custom_modules/create_update_module`
      const mapping_object = {}

      let request = {
        bot_id,
        user_id,
        name,
        description,
        columns_json
      }
      dispatch('updateAjaxDialog', [true, true]);

      await axios.post(url, request, {
        headers: {
          "X-CSRF-TOKEN": token
        },
      })
          .then( async (response) => {
               //dispatch('updateAjaxDialog', [false, false]);
                if ( response?.data?.status === 'success' ) {
                  success = true;
                 console.log('CustomDataAxiosCreateModel success!')
                 dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.model.created') ]);
                }
              },
              (err) => {
               dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
              }
          );

      return success;
    },

    /**
     * Delete custom data model with all data
     * @method CustomDataAxiosDeleteModel
     * @param name - model name
     */
    async CustomDataAxiosDeleteModel({ commit, dispatch, state }, {name}) {

      let success = false;

      const token = Vue.$cookies.get('token_e')
      const engine_url = state.engine_settings.active_url;
      const bot_id = state.bot_settings.active_id;
      const user_id = state.user.id;

      const url = `${engine_url}/kw/custom_modules/delete_module/`


      let request = {
        bot_id,
        user_id,
        name
      }

      console.log(request)
      dispatch('updateAjaxDialog', [true, true]);

      await axios.delete(url, {
        headers: {
          "X-CSRF-TOKEN": token
        },
          data:request
      })
          .then( async (response) => {
               //dispatch('updateAjaxDialog', [false, false]);
                if ( response?.data?.status === 'success' ) {
                 success = true;

                 dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.model.deleted') ]);
                }
              },
              (err) => {
               dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
              }
          );

      return success;
    },

    /**
     * Clear custom data model  data
     * @method CustomDataAxiosClearModel
     * @param name - model name
     */
    async CustomDataAxiosClearModel({ commit, dispatch, state }, {name}) {

      let success = false;

      const token = Vue.$cookies.get('token_e')
      const engine_url = state.engine_settings.active_url;
      const bot_id = state.bot_settings.active_id;

      const url = `${engine_url}/kw/custom_modules/${name}/clear_all/?bot_id=${bot_id}`

      dispatch('updateAjaxDialog', [true, true]);

      await axios.delete(url, {
        headers: {
          "X-CSRF-TOKEN": token
        },

      })
          .then( async (response) => {
               //dispatch('updateAjaxDialog', [false, false]);
                if ( response?.data?.status === 'success' ) {
                 success = true;

                 dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.model.cleared') ]);
                }
              },
              (err) => { 
               dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
              }
          );

      return success;
    },





};

const getters = {
      custom_data_tables: (state)=>{
        return state.custom_data.tables
      },

     custom_data_tables_select: (state)=>{
      let tables = []
         for( let t in state.custom_data.tables){
             tables.push({id: state.custom_data.tables[t].id, name:state.custom_data.tables[t].name})
         }
    return tables
  }
};

export default {
  state,
  mutations,
  actions,
  getters,
};