<template>
  <v-container>

    <!--SELECT MODEL-->
    <v-row>
      <v-col>
       <v-select
              v-model="selectedTable"
              :items="[{id:-1,name:$t('modules.customdata.model.new')}].concat(custom_data_tables_select)"
              item-text="name"
              item-value="id"
              :placeholder="$t('modules.customdata.select_model')"
              :hint="$t('modules.customdata.select_model')"
              persistent-hint
              outlined
              hide-details
              dense>
       </v-select>
        </v-col>
    </v-row>

    <div class="mb-5">
      <v-card class="pt-5">
         <v-form v-model="valid">
        <v-container>
        <v-row>
          <v-col>
            <v-text-field :label="$t('modules.customdata.model.name')"
                          v-model="name"
                           :rules="nameRules"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field :label="$t('modules.customdata.model.description')"
                          v-model="description"
                           :rules="descRules"
            ></v-text-field>
          </v-col>
        </v-row>
          <v-row>
            <v-col>

            <p>{{$t('modules.customdata.model.json')}}</p>
            <VJsoneditor v-model="json_obj" :options="options" ></VJsoneditor>
              </v-col>
            </v-row>
             <v-row>
               <v-col>
            <p>{{$t('modules.customdata.model.json_example')}}</p>
            <pre class="json-example">
  {
    "minimal":{"type":"INTEGER"}
    "column_name": {
      "type":"STRING" //Required, can be also "INTEGER" | "DATE" | "BOOLEAN" | "FLOAT",
      "default":"default value", //optional
      "unique":"true"|"false" //optional
    }
  }</pre>
                 </v-col>


      </v-row>


        </v-container>
         </v-form>
          </v-card>
    </div>


    <div style="text-align: center">

      <v-btn
          :disabled="!(json_obj && valid)"
          color="primary"
          elevation="23"
          @click="createModel"
      >{{ $t('modules.customdata.create') }}</v-btn>

       <v-btn v-if="selectedTable && selectedTable!==-1"
               elevation="23"
              color="error" class="ma-2 white--text" @click="deleteModel"
                >{{ $t('modules.customdata.delete') }}
                  <v-icon dark>mdi-trash-can</v-icon>
                </v-btn>
    </div>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import VJsoneditor from 'v-jsoneditor'
import i18n from '@/lang/i18n';

export default {
  name: "CreateTab",

   components: {
            VJsoneditor
        },

  data: () => ({

    options:{ mode: 'code' },
    name:"",
    description:"",
    valid: false,
    selectedTable:null,
    tables:[],
    table:{},
    json_obj:"",
     nameRules: [
        v => !!v || i18n.t('modules.customdata.model.name_required'),
        v => /^[A-Za-z0-9_]*$/.test(v) || i18n.t('modules.customdata.model.name_required')
      ],

     descRules: [
        v => !!v || i18n.t('modules.customdata.model.description_required'),
        v => v.length>10 || i18n.t('modules.customdata.model.description_required')
      ],

  }),

  async mounted() {
    await this.CustomDataAxiosGetTables();
    this.tables = this.custom_data_tables
  },

  watch: {
    selectedTable(t) {
       console.log(t)
      let tt_obj=null
      for(let tt of this.tables){
        if(tt.id === t){
          tt_obj=tt
          break;
        }
      }


      if(tt_obj!==null){
        this.name = tt_obj.name
        this.description = tt_obj.description
        this.json_obj = tt_obj.columns_json
      }else{
          this.name = ""
        this.description =""
        this.json_obj = {}
      }

    },
  },

  computed: {
    ...mapGetters([
      'dialog',
      'engine_settings',
      'bot_settings',
      'current_tab_type',
      'all_bot_data_init',
      'custom_data_tables_select',
      'custom_data_tables',
      'user',
    ]),
  },

  methods: {
    ...mapActions(['CustomDataAxiosGetTables',
      'CustomDataAxiosCreateModel',
      'CustomDataAxiosDeleteModel']),

    async createModel(){

       await this.CustomDataAxiosCreateModel({
        name: this.name,
        description:this.description,
        columns_json:this.json_obj
      });
        await this.CustomDataAxiosGetTables();
         this.tables = this.custom_data_tables

    },


    async deleteModel(){

       if (confirm(i18n.t('modules.customdata.model.confirm_delete'))) {
         await this.CustomDataAxiosDeleteModel({name: this.name})
         await this.CustomDataAxiosGetTables();
         this.tables = this.custom_data_tables
       }

    },


  },
};
</script>


<style lang="scss">
.json-example {
  font-size: 12px;
  color:#050038;
  background-color: #FFFBD1;
  padding: 20px;
}
</style>