import HELPERS from "@/modules/OperatorPanel/helpers";
import Vue from "vue";
import axios from "../../axios-middleware";
import i18n from "@/lang/i18n.js";

const state = {
  operator_panel: {
    userInfo: null,
    allUsersList: [],
    statuses: [],
  },
};

const mutations = {
  OP_SET_USER_INFO(state, userInfo) {
    Vue.set(state.operator_panel, 'userInfo', userInfo);
  },

  OP_SET_ALL_USERS_LIST(state, usersList) {
    state.operator_panel.allUsersList = usersList;
  },

  OP_SET_STATUSES_LIST(state, statuses) {
    state.operator_panel.statuses = statuses;
  },
};

const actions = {
  async OpAxiosGetMyInfo({ commit }) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/user/info/`;

    const user = await axios
      .get(url)
      .then((response) => {
        return response.data?.data?.user || null;
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t('common.error'), i18n.t('common.error_get')]);
        return null;
      });

    commit("OP_SET_USER_INFO", user);

    return user;
  },

  async opGetAllUsersList({ commit, state }) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/users/list/`;

    const userList = await axios
      .get(url)
      .then((response) => {
        return response.data?.data?.users || [];
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t('common.error'), i18n.t('common.error_get')]);
        return [];
      });

    const urlSettings = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/ent/settings/list/`;
    const opSettings = await axios
        .post(urlSettings)
        .then((response) => {
          console.log('response.data',response.data)
          return response.data?.data?.settings || [];
        })
        .catch((err) => {
          HELPERS.handleError([err, i18n.t('common.error'), i18n.t('common.error_get')]);
          return [];
        });

    const connectOperatorFromOtherSkillGroup = opSettings.find(s => s.key === 'connect_second_operator_from_other_skill_group')

    if (connectOperatorFromOtherSkillGroup?.value) {
      if (+connectOperatorFromOtherSkillGroup.value) {
        commit("OP_SET_ALL_USERS_LIST", userList);
      } else {
        setTimeout(() => {
          const data = userList.filter(u => {
            if (u?.skill_groups?.length) {
              const data = u.skill_groups.map(s => {
                return s.subjects.find(item => item?.id === state.operator_panel.activeChatGroup)
              })

              if (data) {
                const common = u?.kw_user?.role?.common

                if (typeof common === 'string') {
                  JSON.parse(u?.kw_user?.role?.common);
                }

                return u;
              }
            }
          })

          commit("OP_SET_ALL_USERS_LIST", data);
        })
      }
    }
  },

  async opGetStatusesList({ commit }) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/statuses/list/`;

    const statuses = await axios
      .get(url)
      .then((response) => {
        return response.data?.data?.statuses || [];
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t('common.error'), i18n.t('common.error_get')]);
        return [];
      });

    commit('OP_SET_STATUSES_LIST', statuses);
  },

  async opSetUserStatus({ dispatch }, data) {
    dispatch("updateAjaxDialog", [true, true]);

    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/user/update_own_availability/`;

    const request = data;

    const success = await axios
      .post(url, request)
      .then((response) => {
        dispatch("updateAjaxDialog", [false, false]);
        return response.data?.status === "success";
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t('common.error'), i18n.t('common.error_get')]);
        return false;
      });

    return success;
  },
};

const getters = {
  opUserInfo: (state) => state.operator_panel.userInfo,

  opAllUsersList: (state) => {
    const users = state.operator_panel.allUsersList;

    const setName = (user) => {
      const name = HELPERS.formatUserName(user) || `${this.$t('modules.op.texts.settings_saved_error')} (id ${user.id})`;
      user.name = name;
    };

    users.forEach(setName);

    return users;
  },

  opStatusesList: (state) => {
    return state.operator_panel.statuses;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
