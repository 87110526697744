<template>

    <v-container class="table-statistics__wrapper">      

      {{/** FILTERS FOR EACH HEADER **/}}
      <v-expansion-panels
        class="mb-8"
      >
        <v-expansion-panel>
          <v-expansion-panel-header
            disable-icon-rotate
          >
            {{i18n.t('modules.customdata.entity.filters')}}
            <template v-slot:actions>
              <v-icon color="primary">
                mdi-filter
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content
          >            
            <v-form
              ref="formFilters"
            >      
              <v-row justify="center" class="custom-data__filters">
                <v-col
                  v-for="filter in filters"
                  :key="filter.name"
                  lg="auto"
                  md="4"
                  sm="12"
                  cols="12"
                >

                  <v-text-field
                    v-if="filter.type === 'INTEGER'"
                    class="custom-data__filters-number"
                    v-model="requestFilter[filter.name + '|' + filter.type]"
                    :label="filter.name"
                    type="number" 
                    hide-details
                  ></v-text-field>
                  
                  <v-select
                    v-if="filter.type === 'STRING'"
                    v-model="requestFilter[filter.name + '|' + filter.type]"
                    :items="multipleSelectItems[0][filter.name].values"
                    :menu-props="{ maxHeight: '400' }"
                    :label="filter.name"
                    multiple
                    persistent-hint
                  ></v-select>

                  <v-menu
                    v-if="filter.type === 'DATE'"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="requestFilter[filter.name + '|' + filter.type]"
                        :label="filter.name"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="requestFilter[filter.name + '|' + filter.type]"
                      no-title
                      :locale="localeLang"
                      scrollable
                      range
                    >                
                    </v-date-picker>
                  </v-menu>

                  <v-text-field
                    v-if="filter.type === 'FLOAT'"
                    class="custom-data__filters-number"
                    v-model="requestFilter[filter.name + '|' + filter.type]"
                    :label="filter.name"
                    type="number" 
                    hide-details
                  ></v-text-field>

                  <v-checkbox
                    v-if="filter.type === 'BOOLEAN'"
                    v-model="requestFilter[filter.name + '|' + filter.type]"
                    :label="filter.name"
                    value="1"
                    hide-details
                  ></v-checkbox>

                </v-col>
              </v-row>

              <v-row justify="center">
                <v-col md="2" sm="12">
                  <v-btn color="primary" elevation="0" block @click="getItems()">
                    {{i18n.t('modules.customdata.entity.search')}}
                  </v-btn>
                </v-col>
                <v-col md="2" sm="12">
                  <v-btn color="primary" elevation="0" block @click="reset()">
                    {{i18n.t('modules.customdata.entity.reset')}}
                  </v-btn>
                </v-col>
              </v-row>
            
            </v-form>
            
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      {{ /** TABLE **/}}
      <v-data-table
        :options.sync="options"
        :server-items-length="length"
        :loading="loading"
        :headers="headers"
        :items="items"
        class="crud-table elevation-1"
        sort-by="id"
        dense
        :sort-desc="sort_desc"
        :group-by="group_by"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 30, 100]
        }"
        :hide-default-footer="false"
        calculate-widths
      >
        
        {{/**ITEM CONTROL SLOT **/}}
        <template v-slot:item.actions="{ item }">
          <div class="custom-data__filters--actions">
            <v-btn icon color="green" class="mt-1"
              @click="editItem(item.id)"
              :title="i18n.t('modules.customdata.entity.edit')"
            >
              <v-icon small>$iconedit</v-icon>
            </v-btn>
            <v-btn icon color="red" class="mt-1"
              @click="deleteItem(item.id)"
              :title="i18n.t('modules.customdata.entity.delete')"
            >
              <v-icon small>$icondelete</v-icon>
            </v-btn>
          </div>          
        </template>

      </v-data-table>

      {{ /** ADD BUTTON **/}}
      <v-flex
        xs12
        sm12
        class="table-statistics__btn"
        v-if="canSave"
      >
        <v-btn :text="$vuetify.breakpoint.smAndDown"
          color="primary"
          @click="modal = true"
        >
          <v-icon left> mdi-plus </v-icon>
          {{ i18n.t('modules.customdata.entity.add') }}
        </v-btn>
      </v-flex>

      {{/** MODAL WINDOW EDIT/ADD ENTITY **/}}
      <template>
        <div class="text-center">
          <v-dialog v-model="modal">
            <v-form ref="form" v-model="form_valid" class="settings-form">
              <v-card>
                <v-card-title class="justify-center">
                  <span class="headline">{{
                    i18n.t('modules.customdata.entity.modal_title')
                  }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container grid-list-md>


                    <v-layout wrap>

                     {{/** ENTITY FIELDS **/}}
                      <template v-for="(field, key) in entity_fields">

                        <v-flex xs12 sm6 lg4 :key="key" >
                          {{/*Вывод полей с кастомизацией*/}}
                          <template>
                            {{/*STRING FIELDS*/}}

                            <v-text-field
                              v-if="['STRING'].includes(field.type)"
                              v-model.trim="item_obj[key]"
                              :label="key"
                              :rules="field.rules"
                            ></v-text-field>

                            {{/*INTEGER FIELDS*/}}
                            <v-text-field
                              v-if="['INTEGER'].includes(field.type)"
                              v-model.trim="item_obj[key]"
                              :label="key"
                              :rules="[rules.onlyDigits]"
                            ></v-text-field>

                            {{/*FLOAT FIELDS*/}}
                            <v-text-field
                              v-if="['FLOAT'].includes(field.type)"
                              v-model.trim="item_obj[key]"
                              :label="key"
                              :rules="[rules.floats]"
                            ></v-text-field>
                            
                            {{/*BOOL FIELDS*/}}
                            <v-checkbox
                              v-if="field.type === 'BOOLEAN'"
                              v-model="item_obj[key]"
                              :label="key"
                              hide-details
                              :rules="field.rules"
                            ></v-checkbox>

                            {{/*DATE FIELDS*/}}
                            <!-- <v-text-field
                              v-if="['DATE'].includes(field.type)"
                              v-model.trim="item_obj[key]"
                              :label="key"
                              :rules="field.rules"
                            ></v-text-field> -->

                            <v-menu
                              v-if="['DATE'].includes(field.type)"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model.trim="item_obj[key]"
                                  :label="key"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  clearable
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="item_obj[key]"
                                no-title
                                :locale="localeLang"
                                scrollable
                              >                
                              </v-date-picker>
                            </v-menu>















                          </template>
                        </v-flex>
                      </template>

                      <v-flex xs12 sm12 class="mt-5" style="text-align: center">
                        <v-btn
                          class="mr-2"
                          color="white"
                          elevation="24"
                          @click="modal = false"
                          >{{ i18n.t('common.close') }}</v-btn
                        >
                        <v-btn color="primary" @click="save">{{
                          i18n.t('common.save')
                        }}</v-btn>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-form>
          </v-dialog>
        </div>
      </template>

      {{/** EXPORT ENTITY BTN **/}}
      <v-row justify="space-between">
        <v-col
          cols="12"
          lg="auto"
          sm="12"
        >
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                outlined
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left> mdi-download </v-icon>
                {{i18n.t('modules.customdata.entity.export')}}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in exportType"
                :key="index"
                link
                v-on:click="getExportFile(item)"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        
        <v-col
          cols="12"
          lg="auto"
          sm="12"
        >
          <v-btn
            color="error" outlined class="white--text" @click="clearModel"
          >
            <v-icon dark>mdi-trash-can</v-icon>{{ $t('modules.customdata.clear') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Vue from 'vue';
import axios from 'axios';
import i18n from '@/lang/i18n';


export default {
  name: 'CRUD',

  props: ['entity', 'multiple_entity', 'entity_fields'],
  components: {},

  data: function () {
    return {
      i18n,

      module_name: 'customdata',
      sort_desc: false,
      loading: false,
      group_by:null,
      options: {},

      length: null,

      entity_data: null,

      extra_data: {},

      modal: false,

      headers: [],

      item_obj: {
        id: null,
      },

      item_empty_obj: {},

      type: ['integer', 'boolean'],

      form_valid: true,

      entity_fields_obj: {},

      funnels: [],

      filters: {},
      requestFilter: {},

      multipleSelectItems: [],

      dates: [],
      menu: false,

      exportType: ['csv', 'xls', 'pdf', 'xml', 'html'],
    };
  },

  mounted: async function () {
    this.entity_fields_obj = {};

    //Сохранение пустого эталонного объекта и сохранение в объект полей сущности
    for (let f in this.entity_fields) {
      let field = this.entity_fields[f]


      if (field.name && field.type) {
        this.entity_fields_obj[field.name] = field;

        let value;
        switch (field.type) {
          case 'STRING':
          case 'FLOAT':
          case 'INTEGER':
          case 'DATE':
            value = '';
            break;
          case 'BOOLEAN':
            value = false;
            break;
          default:
            value = null;
        }

        Vue.set(this.item_obj, field.name, value);
      }
    }

    //Запись эталонного объекта
    this.item_empty_obj = this._.cloneDeep(this.item_obj);
    this.length = 0;
    this.headers = [];

    for (let f in this.entity_fields) {
       let field = this.entity_fields[f]
      let item = {
        align: 'start',
        sortable: true,
      };

      item.value = f;
      item.text = f;

      //if (field.align) item.align = field.align;
      //if (typeof field.sortable === 'boolean') item.sortable = field.sortable;

      this.headers.push(item);

    }

    this.headers.push({ text: '', value: 'actions', sortable: false });
    // console.log(this.headers, 'MOUNTED this.headers')

    this.getmultipleSelectItems()
  },

  computed: {
    ...mapGetters(['rules', 'user', 'bot_settings', 'engine_settings']),

    items: function () {
      let items = [];

      if (this.entity_data) {
        for (let item of this.entity_data) {
          if (item.id) {
            items.push(item);
          }
        }
      }
      // console.log('this.entity_data',items)
      return items;
    },

    canSave() {
      return true;
      /** TODO check rights **/
      // return this.user && this.user.role.permissions.statistics.settings.sections[this.multiple_entity.split('_')[1]].canSave;
    },
    localeLang() {
      return i18n.locale
    },
  },

  methods: {
    ...mapActions(['CustomDataAxiosClearModel']),

    formatDate(date){
      return "2022-01-02"
    },

    async getItems() {
      this.entity_data = await this.getCustomEntityList(
        this.multiple_entity,
        this.options
      );
    },

    /**
     * Edit item button
     * @method editItem
     * @param id
     * */
    async editItem(id) {
      //Очистка формы
      if (this.$refs && this.$refs.form) {
        this.$refs.form.reset();
      }

      if (this.entity_data && id) {
        for (let item of this.entity_data) {
          if (item.id && parseInt(item.id) === parseInt(id)) {
            for (let param in item) {

              Vue.set(this.item_obj, param, item[param]);
            }
            this.modal = true;
          }
        }
      }
    },

    /**Delete item button
     * @method deleteItem
     * @param id item id
     * */
    async deleteItem(id) {
      if (confirm(i18n.t('modules.customdata.entity.confirm_delete'))) {
        if (await this.deleteCustomEntityList(this.entity, id)) {
          this.getItems();
        }
      }
    },

    /**
     * Save button in edit modal
     * @method save
     */
    async save() {
      if (this.$refs.form.validate()) {
        let request = this._.cloneDeep(this.item_obj);

        if (!request.id) {
          request.id = null;
        } else if (request.id && request.id === '') {
          request.id = null;
        }

        if (!request.kw_user_id) {
          request.kw_user_id = null;
        } else if (request.kw_user_id && request.kw_user_id === '') {
          request.kw_user_id = null;
        }

        if (await this.saveCustomEntityList(this.entity, request, request.id)) {
          this.getItems();          
          this.modal = false;
          this.$refs.form.reset()
        }
      } else {
        return false;
      }
    },

    /**
     * Get list of custom data entities
     * @method getCustomEntityList
     */

    async getCustomEntityList(multiple_entity, options) {

      let data = null;
      this.filters = {}

      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;
      let url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/list?`

      //pagination
      let itemsPerPageValue = this.options?.itemsPerPage
      let curentPage = this.options?.page
      let offset = 0;
      if (curentPage > 1) offset = (curentPage - 1) * itemsPerPageValue

      //set order param
      if (options?.sortBy !== undefined && options?.sortBy[0] !== undefined) {
        let orderValue;
        this.options.sortDesc[0] === true ? orderValue = 'desc' : orderValue = 'asc'
				url += '&order[' + this.options?.sortBy[0]+']=' + orderValue;
			}

      //set filters
      //clear filters when switched multiple_entity
      if (multiple_entity === undefined) {
        for (let req in this.requestFilter) delete this.requestFilter[req];
      }
      // console.log(this.requestFilter, 'requestFilter')
      if(Object.keys(this.requestFilter).length != 0) {
        let requestFilter = this.requestFilter

        for (const [key, value] of Object.entries(requestFilter)) {
          let pKey = key.split('|')[0]
          let type = key.split('|')[1]

          if(type === 'STRING' && value.length > 0) {
            value.map(function(elVal) {
              url += '&filter[' + pKey + '][in][]=' + elVal;
            })            
          } else if (type === 'BOOLEAN' && value !== undefined && value !== null) {
            url += '&filter[' + pKey + '][like]=%' + value + '%';
          } else if (type === 'DATE' && value !== undefined && value.length > 0) {
            url += '&filter[' + pKey + '][gt]=' + value[0];
            url += '&filter[' + pKey + '][lt]=' + value[1];
          } else if (type === 'INTEGER' && value !== undefined) {
            url += '&filter[' + pKey + '][like]=%' + value + '%';
          } else if (type === 'FLOAT' && value !== undefined) {
            url += '&filter[' + pKey + '][like]=%' + value + '%';
          }
        }
      }

      let params = { 
        bot_id: bot_id,
        limit: itemsPerPageValue,
				offset: offset,
      }

      this.$store.dispatch('updateAjaxDialog', [true, true]);
      await axios({
        method: 'GET',
				url: url,
				headers: {
					'X-CSRF-TOKEN': token,
				},
				params,
      })
          .then( (response) => {
                this.$store.dispatch('updateAjaxDialog', [false, false]);

                if ( response?.data?.status === 'success' ) {
                  const columns = response.data.data.columns;
                  data = response.data.data.rows;
                  this.length = response.data.data.count;

                  //set filters from headers
                  this.filters = columns

                  //set headers
                  this.headers = columns.map(c => {
                    return {
                      align: 'start',
                      name: c.name,
                      rules: [],
                      show_in_popup: true,
                      show_in_table: true,
                      sortable: true,
                      text: c.name,
                      value: c.name,
                      type: c.type,
                    }
                  })

                  this.headers.push({ text: '', value: 'actions', sortable: false });
                }
              },
              (err) => {
                this.$store.dispatch('updateAjaxDialogError', [ err, 'Ошибка', 'обновления' ]);
              }
          );

      return data;
    },

    /**
     * Export of custom data entities in file
     * @method getExportFile
     */
    getExportFile(fileType) {

      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;
      let url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/export_data?`

      if(Object.keys(this.requestFilter).length != 0) {
        let requestFilter = this.requestFilter

        for (const [key, value] of Object.entries(requestFilter)) {
          let pKey = key.split('|')[0]
          let type = key.split('|')[1]

          if(type === 'STRING' && value.length > 0) {
            value.map(function(elVal) {
              url += '&filter[' + pKey + '][in][]=' + elVal;
            })            
          } else if (type === 'BOOLEAN' && value !== undefined && value !== null) {
            url += '&filter[' + pKey + '][like]=%' + value + '%';
          } else if (type === 'DATE' && value !== undefined && value.length > 0) {
            url += '&filter[' + pKey + '][gt]=' + value[0];
            url += '&filter[' + pKey + '][lt]=' + value[1];
          } else if (type === 'INTEGER' && value !== undefined) {
            url += '&filter[' + pKey + '][like]=%' + value + '%';
          } else if (type === 'FLOAT' && value !== undefined) {
            url += '&filter[' + pKey + '][like]=%' + value + '%';
          }
        }
      }

      let params = { 
        bot_id: bot_id,
        format: fileType,
      }

      this.$store.dispatch('updateAjaxDialog', [true, true]);
      axios({
        method: 'GET',
				url: url,
				headers: {
					'X-CSRF-TOKEN': token,
				},
        responseType: 'arraybuffer',
				params,
      })
      .then( (response) => {
          this.$store.dispatch('updateAjaxDialog', [false, false]);
          this.forceFileDownload(response, 'custom_data_' + this.multiple_entity + '-' + bot_id + '.' + fileType)
        },
        (err) => {
          this.$store.dispatch('updateAjaxDialogError', [ err, 'Ошибка', 'Скачивания' ]);
        }
      );

    },

    /**
     * Forces downloading file from browser after get it by axios
     * @method forceFileDownload
     * @param response
     * @param title
    */
    forceFileDownload(response, title) {
      // console.log(title)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
      link.remove()
      this.download_started = false;
    },

    /**
     * @method saveCustomEntityList
     * @param entity
     * @param request
     * @param id
     * */

    async saveCustomEntityList(entity, request, id) {
      let success = false;

       const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;


      let url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/update/${id}/?bot_id=${bot_id}`
      if(id===null)
        url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/create/?bot_id=${bot_id}`
      const params = { bot_id }


      this.$store.dispatch('updateAjaxDialog', [true, true]);

      console.log(request)
      console.log(url)
      await axios
        .post(
          url,
          request,
          {
            headers: {
              'X-CSRF-TOKEN': token,
            },
          }
        )
        .then(
          (response) => {
            this.$store.dispatch('updateAjaxDialog', [false, false]);
            if (
              response.data &&
              response.data.status &&
              response.data.status === 'success'
            ) {
              success = true;
            }
          },
          (err) => {
            if (
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message_alias
            ) {
              let message_alias = err.response.data.message_alias;

              if (message_alias === 'access_error') {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error'),
                  this.$t('common.error_permitions'),
                ]);
              } else {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error'),
                  this.$t('common.error_save'),
                ]);
              }
            } else {
              this.$store.dispatch('updateAjaxDialogError', [
                err,
                this.$t('common.error'),
                this.$t('common.error_save'),
              ]);
            }
          }
        );

      return success;
    },

    /**
     * Delete entity from the list
     * @method deleteCustomEntityList
     * @param entity
     * @param id
     */
    async deleteCustomEntityList(entity, id) {
      let success = false;
      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;
      const url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/delete/${id}/?bot_id=${bot_id}`
      const params = { bot_id }


      this.$store.dispatch('updateAjaxDialog', [true, true]);
      await axios
        .delete(
          url,
          {
            headers: {
              'X-CSRF-TOKEN': token,
            }
          }
        )
        .then(
          (response) => {
            this.$store.dispatch('updateAjaxDialog', [false, false]);
            if (
              response.data &&
              response.data.status &&
              response.data.status === 'success'
            ) {
              success = true;

              this.$store.dispatch('axiosGetCurrentSectionNodesData');
            }
          },
          (err) => {
            if (
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message_alias
            ) {
              let message_alias = err.response.data.message_alias;

              if (message_alias === 'access_error') {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error'),
                  this.$t('common.error_permitions'),
                ]);
              } else {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error'),
                  this.$t('common.error_delete'),
                ]);
              }
            } else {
              this.$store.dispatch('updateAjaxDialogError', [
                err,
                this.$t('common.error'),
                this.$t('common.error_delete'),
              ]);
            }
          }
        );

      return success;
    },

    getItemName(item, value) {
      let text = item[value];
      return text;
    },

    reset() {
      for (let req in this.requestFilter) delete this.requestFilter[req];
      this.$refs.formFilters.reset()
      this.getItems()
    },

    async getmultipleSelectItems() {
      
      this.multipleSelectItems = [];

      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;
      let url = `${engine_url}/kw/custom_modules/get/${this.multiple_entity}`

      let params = { bot_id }

      this.$store.dispatch('updateAjaxDialog', [true, true]);
      await axios({
        method: 'GET',
				url: url,
				headers: {
					'X-CSRF-TOKEN': token,
				},
				params,
      })
      .then( (response) => {
            this.$store.dispatch('updateAjaxDialog', [false, false]);

            if ( response?.data?.status === 'success' ) {
              this.multipleSelectItems.push(response?.data?.filters)
            }
          },
          (err) => {
            this.$store.dispatch('updateAjaxDialogError', [ err, 'Ошибка', 'обновления' ]);
          }
      );
    },

    async clearModel(){
      if (confirm(i18n.t('modules.customdata.model.confirm_clear'))) {
        await this.CustomDataAxiosClearModel({name: this.multiple_entity})
        this.getItems()
      }
    },
  },

  watch: {
    modal: function (val) {
      if (!val && this.item_obj?.id) {
        this.$refs.form.resetValidation();
        this.item_obj = this._.cloneDeep(this.item_empty_obj);
      }
    },

    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },

    multiple_entity: async function () {
      this.options = {}
      this.getmultipleSelectItems()
      this.entity_data = await this.getCustomEntityList();      
    }
  },
};
</script>

<style lang="scss">
.options {
  width: 110px;
}

.table-statistics {
  box-shadow: 0px 5px 15px rgba(77, 76, 172, 0.12);
  border-radius: 5px;

  &__btn {
    text-align: center;
    margin: 24px auto 32px;

    @media screen and (max-width: 959px) {
      text-align: left;
      margin: 20px auto 20px 0;
    }
  }
}

.custom-data__filters {
  &-number{
    max-width: 90px
  }
  &--panel{
    color: white;
  }
  &--actions{
    display: flex;
  }  
}

.crud-table {
  .v-data-table-header th {
    white-space: nowrap;
  }
}

@media screen and (max-width: 959px) {
  .table-statistics__wrapper {
    display: flex;
    flex-direction: column;

    .table-statistics {
      order: 2;
    }
  }
  .table-statistics__btn {
    text-align: left;
  }

  .crud-table{
    width: 100%;
  }
}
</style>
