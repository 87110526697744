import Vue from 'vue'
// import _ from 'lodash'
import axios from "axios/index";

const state = {
    is_root: false,
    user: null,
}

const actions  = {

    //инициация
    async loadUserData({commit, dispatch, state, getters}, mode) {
        let token = Vue.$cookies.get('token_c')

        await axios
          .get(process.env.VUE_APP_CONSTRUCTOR_URL + '/kw/user', {
            headers: {
              'X-CSRF-TOKEN': token,
            },
          })
          .then(
            (response) => {
              if (response.data && response.data.data) {
                if (response.data.data && response.data.data.user) {
                  let user = response.data.data.user;

                  if (user.root && parseInt(user.root) > 0) {
                    // TODO: remove
                    commit('SET_ROOT', true);
                  }

                  commit('SET_USER', user);
                }
              }else{
                    dispatch('updateAjaxDialogError', [ 'Error', 'Error getting user data']);
                console.log("<constructor> [ERROR] Error getting user data")
              }
            },
            (err) => {
                dispatch('updateAjaxDialogError', [ err, 'Error', 'Error getting user data']);
                console.log("<constructor> [ERROR] Error getting user data")
            }
          );

        // //Добавление пункта меню
        // let menu_item = {title: 'Кастомный модуль', module: 'custom', icon: 'mdi-cog', show: true}
        // commit('ADD_MENU_ITEM', menu_item)
        //
        // //Добавление модуля
        // let module_name = 'custom'
        // let module_obj = {
        //     use_tabs: true,
        //     tabs_items: [
        //         {tab: 'Список серверов', content: '', show:true, type: 'engines'},
        //         {tab: 'Список ботов', content: '', show:true, type: 'bots'},
        //         {tab: 'Настройка бота', content: '', show:true, type: 'bot'}
        //     ],
        // }
        // commit('ADD_MODULE', {module_name, module_obj})
    },
}

const mutations = {
    //Настройки
    SET_ROOT(state, val) {
        state.is_root = val
    },

    SET_USER(state, val) {
        state.user = val;
    },
}


const getters = {
    is_root: state => {
        return state.is_root;
    },
}

export default {
    state,
    mutations,
    actions,
    getters
}