/**
 * Created by Bezsmertnyi Ievgen on 12.09.2020.
 */

const getTranslateFunc = function () {
  let i18n;

  try {
    i18n = require('@/lang/i18n.js').default;
  } catch (error) {
    // console.log(error);
  }

  return i18n
    ? (translateAlias) => i18n.t(translateAlias)
    : (translateAlias) => translateAlias;
};

const getTranslate = getTranslateFunc();

//Должен называться так же как и в modules_components_obj
let module_name = 'Statistics';

let module_settings = {
  module_name,
};

//Корневой урл модуля
let module_alias = 'statistics';

//Полный список разрешений модуля
module_settings.permits = {
  front: {
    //Отображение пунктов меню
    statistics_show_menu_item: {
      description: 'Отображать в меню пункт статистики',
    },
    statistics_show_settings_menu_item: {
      description: 'Отображать настройки статистики',
    },
    statistics_show_settings_targets_tab_item: {
      description: 'Отображать цели в настройках статистики',
    },
    statistics_show_settings_funnels_tab_item: {
      description: 'Отображать воронки в настройках статистики',
    },
    //Отображение табов
    statistics_show_messengers_tab_item: {
      description: 'Отображать таб мессенджеров статистики',
    },
    statistics_show_sections_tab_item: {
      description: 'Отображать таб скций',
    },
    statistics_show_funnels_tab_item: {
      description: 'Отображать таб воронок',
    },
    statistics_show_operators_tab_item: {
      description: 'Отображать таб операторов',
    },
  },

  back: {},
};

//Пункты в меню
module_settings.menu_items = [
  {
    title: getTranslate('modules.statistics.module_settings.statistics'),
    module: module_name,
    alias: module_alias,
    icon: 'mdi-chart-areaspline',
    permits: {
      show: 'statistics_show_menu_item',
    },
    show: true,
    route: 'statistics',
  },
  {
    title: getTranslate(
      'modules.statistics.module_settings.statistics_settings'
    ),
    module: module_name + '_settings',
    alias: module_alias + '/settings',
    icon: 'mdi-chart-timeline',
    permits: {
      show: 'statistics_show_settings_menu_item',
    },
    show: true,
    route: 'statistics_settings',
  },
];

//Табы модуля
module_settings.tabs_items = {};

module_settings.tabs_items[module_name] = {
  use_tabs: true,
  tabs_items: [
    {
      tab: getTranslate('modules.statistics.module_settings.sections'),
      content: '',
      show: true,
      type: 'statistic_sections',
      alias: '/',
      permits: { show: 'statistics_show_sections_tab_item' },
      route: 'sections',
    },
    {
      tab: getTranslate('modules.statistics.module_settings.operators'),
      content: '',
      show: true,
      type: 'statistic_operators',
      alias: '/',
      permits: { show: 'statistics_show_operators_tab_item' },
      route: 'operators',
    },
    {
      tab: getTranslate('modules.statistics.module_settings.funnels'),
      content: '',
      show: true,
      type: 'statistic_funnels',
      alias: '/',
      permits: { show: 'statistics_show_funnels_tab_item' },
      route: 'funnels',
    },
    {
      tab: getTranslate('modules.statistics.module_settings.tables'),
      content: '',
      show: true,
      type: 'statistic_tables',
      alias: '/',
      permits: { show: 'statistics_show_tables_tab_item' },
      route: 'tables',
    },
  ],
};

module_settings.tabs_items[module_name + '_settings'] = {
  use_tabs: true,
  tabs_items: [
    {
      tab: getTranslate('modules.op.module_settings.funnels'),
      content: '',
      show: true,
      type: 'funnels',
      alias: '/funnels',
      permits: {
        show: 'statistics_show_settings_funnels_tab_item',
      },
      route: 'funnels',
    },
    {
      tab: getTranslate('modules.op.module_settings.targets'),
      content: '',
      show: true,
      type: 'targets',
      alias: '/targets',
      permits: {
        show: 'statistics_show_settings_targets_tab_item',
      },
      route: 'targets',
    },
  ],
};

//Подключенные компоненты
module_settings.components = [
  {
    name: module_name,
    path: 'Component/Statistics.vue',
  },
  {
    name: module_name + '_settings',
    path: 'Component/Settings.vue',
  },
];

//Левая колонка в конструкторе сценария
module_settings.left_sidebar = {
  sidebar_items: [
    {
      show: true,
      title: 'Точка статистики',
      icon: 'mdi-bookmark-plus-outline',
      color: 'blue',
      type: 'statistics',
    },
  ],
};

//SVG блок в конструкторе сценария
module_settings.svg_area = {
  svg_icons_path: {
    statistics:
      'M17 18V5H7v13l5-2.18L17 18m0-15a2 2 0 0 1 2 2v16l-7-3l-7 3V5a2 2 0 0 1 2-2h10m-6 4h2v2h2v2h-2v2h-2v-2H9V9h2V7z',
  },
};

//Экспорт для vue и для node
module.exports = { module_settings };
module.exports.default = { module_settings };
