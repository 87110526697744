import Vue from 'vue';
import axios from 'axios/index';
import i18n from '@/lang/i18n.js';

const state = {
  statistics: {
    statisticsFullData: {},
  },
};

const mutations = {
  SET_STATISTICS_FULL_DATA (state, payload) {
    Vue.set(state.statistics, 'statisticsFullData', payload);
  },
};

const actions = {

  async statisticsGetFullData ({state, dispatch, commit}, query) {
    const url = state.engine_settings.active_url;
    const token = Vue.$cookies.get('token_e');
    const bot_id = parseInt(state.bot_settings.active_id);
    const request = {
      bot_id,
    };

    let filter = null
    if (query && query.length) {
      filter = {
        params: {
          filter: {
            "createdAt|date_from": query[0],
            "createdAt|date_to": query[1]
          }
        }
      }
    }

    dispatch('updateAjaxDialog', [true, true]);

    await axios
      .post(url + '/kw/statistics/full', request, filter,{
        headers: {
          'X-CSRF-TOKEN': token,
        },
      })
      .then(
        (response) => {
          dispatch('updateAjaxDialog', [false, false]);

          if (response.data && response.data.data) {
            commit('SET_STATISTICS_FULL_DATA', response.data.data)
          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [
            err,
            i18n.t('common.error'),
            i18n.t('modules.statistics.texts.error_get_stat_data'),
          ]);
        }
      );
  }
};

const getters = {
  statisticsFullData: (state) => state.statistics.statisticsFullData,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
