<template>
  <svg :width="width" :height="height" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.33502 8.38335H9.15539C9.37297 8.38335 9.58164 8.46978 9.73549 8.62363C9.88933 8.77748 9.97577 8.98615 9.97577 9.20372V11.6648C9.97577 11.8824 9.88933 12.0911 9.73549 12.2449C9.58164 12.3988 9.37297 12.4852 9.15539 12.4852H8.33502C8.11744 12.4852 7.90878 12.3988 7.75493 12.2449C7.60108 12.0911 7.51465 11.8824 7.51465 11.6648V9.20372C7.51465 8.98615 7.60108 8.77748 7.75493 8.62363C7.90878 8.46978 8.11744 8.38335 8.33502 8.38335ZM8.33502 8.38335V5.92223C8.33502 4.61678 8.85361 3.36479 9.77671 2.44169C10.6998 1.51859 11.9518 1 13.2573 1C14.5627 1 15.8147 1.51859 16.7378 2.44169C17.6609 3.36479 18.1795 4.61678 18.1795 5.92223V8.38335M18.1795 8.38335H17.3591C17.1415 8.38335 16.9329 8.46978 16.779 8.62363C16.6252 8.77748 16.5387 8.98615 16.5387 9.20372V11.6648C16.5387 11.8824 16.6252 12.0911 16.779 12.2449C16.9329 12.3988 17.1415 12.4852 17.3591 12.4852H18.1795C18.3971 12.4852 18.6057 12.3988 18.7596 12.2449C18.9134 12.0911 18.9999 11.8824 18.9999 11.6648V9.20372C18.9999 8.98615 18.9134 8.77748 18.7596 8.62363C18.6057 8.46978 18.3971 8.38335 18.1795 8.38335Z" :stroke="iconColor" stroke-width="1.2"/>
    <path d="M-0.00012207 7.11569H4.57129M2.28558 4.82999V9.4014V4.82999Z" :stroke="iconColor" stroke-width="1.2"/>
  </svg>
</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'iconAddOperator'
            },
            width: {
                type: [Number, String],
                default: 20
            },
            height: {
                type: [Number, String],
                default: 14
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>
