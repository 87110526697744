import LAYOUT from '@/layout/layout';
import moduleLayout from '@/views/Dialog/Dialog.vue';

export default [
  {
    path: '/dialog',
    component: LAYOUT.base,
    redirect: '/dialog/scenario',
    children: [
      {
        path: 'scenario',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'DialogScenario',
            component: () => import(/* webpackChunkName: 'dialog-scenario' */ '@/views/Dialog/tabs/DialogTab.vue'),
          },
        ],
      },
      {
        path: 'constants',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'DialogConstants',
            component: () =>import(/* webpackChunkName: 'dialog-constants' */ '@/views/Dialog/tabs/ConstantsTab.vue'),
          },
        ],
      },
    ],
  },
];
