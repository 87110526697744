<template>
	<div class="app-wrapper">
		<v-app-bar
			absolule
			dense
			color="black"
			height="60"
			class="box-shadow-theme main-app-header"
			:class="{ 'box-shadow__ischat': checkCurrentTabIsChat }"
      dark
		>
			{{/*Menu btn*/}}
			<v-app-bar-nav-icon
				@click.stop="drawer = !drawer"
				v-if="checkPermit('kw_show_menu')"
				color="primary"
			></v-app-bar-nav-icon>

			{{/*Menu btn*/}}

			<v-col
				cols="auto"
				class="ml-3 d-flex align-center pa-0"
				v-if="$vuetify.breakpoint.mdAndUp"
			>
				<v-img height="21" width="36" src="@/assets/logo.png"></v-img>
				<v-divider vertical height="28" class="ml-5 mr-5"></v-divider>
				{{/*Bot name*/}}
				<v-toolbar-title
					v-if="checkPermit('show_kw_active_bot_title')"
					>{{ active_bot_title }}</v-toolbar-title
				>
			</v-col>

			<v-spacer v-if="$vuetify.breakpoint.mdAndUp" />

			<template
				v-if="
					renderExtension && extension.show && !checkCurrentTabIsChat
				"
			>
				<v-tabs
					class="header-tabs"
					v-model="tab"
					center-active
					show-arrows
					centered
				>
					<template v-for="item in extension.tabs_items">
						<!-- <v-tab
                            v-if="item.show && checkModuleOrTabPermit(item)"
                            :key="item.type"
                            :href="`#${item.type}`"
                        >
                            {{ item.tab }}
                        </v-tab> -->
						<router-link
							v-if="item.show && checkModuleOrTabPermit(item)"
							:key="item.type"
							:to="`/${current_module}/${item.route}`"
							v-slot="{ navigate, href }"
							custom
						>
							<v-tab
								@click="navigate"
								@keypress.enter="navigate"
								role="link"
								:href="href"
							>
								{{ item.tab }}
							</v-tab>
						</router-link>
					</template>
				</v-tabs>
			</template>

			<div v-if="checkCurrentTabIsChat" class="header-op-title">
				{{ $t('modules.op.module_settings.operator_panel') }}
			</div>

			<portal-target name="destination">
				<!--
                This component can be located anywhere in your App.
                The slot content of the above portal component will be rendered here.
                -->
			</portal-target>

			<v-spacer v-if="$vuetify.breakpoint.mdAndUp" />

			{{/*Menu Account Right*/}}
			<v-col cols="auto" class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
				<v-menu
					min-width="200px"
					transition="slide-y-transition"
					rounded
				>
					<template v-slot:activator="{ on }">
						<v-btn
							v-on="on"
							icon
							color="primary"
							:ripple="false"
							depressed
							id="no-background-hover"
							min-width="100"
							:title="userInfo.email"
						>
							<v-avatar
								class="mr-1"
								height="40"
								width="40"
								min-width="40"
								color="primaryLight"
							>
								<v-img
									height="40"
									width="40"
									:src="userInfo.avatar_img"
								></v-img>

								<v-icon>$iconlogin</v-icon>
							</v-avatar>
							<v-icon>mdi-chevron-down</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item class="justify-center">
							<v-btn
								class=""
								v-on:click="logout"
								depressed
								block
								:ripple="false"
								color="primary"
								width="100%"
							>
								<v-icon class="mr-2">$iconlogout</v-icon
								><span>{{ $t('header.logout') }}</span>
							</v-btn>
						</v-list-item>
					</v-list>
				</v-menu>
			</v-col>

			{{/*Menu Account Right*/}}
		</v-app-bar>

		{{/*Menu list*/}}
		<v-navigation-drawer
			v-model="drawer"
			:absolute="$vuetify.breakpoint.mdAndUp"
			temporary
			class="main-menu-drawer"
			:fixed="$vuetify.breakpoint.smAndDown"
		>
			{{/*mobile header*/}}
			<div
				class="
					menu-mob-header
					d-flex
					justify-space-between
					align-center
				"
				v-if="$vuetify.breakpoint.smAndDown"
			>
				<div class="d-flex align-center">
					<v-btn icon tile @click.stop="drawer = !drawer">
						<v-img
							max-height="40"
							max-width="40"
							src="@/assets/logo.png"
						></v-img>
					</v-btn>

					<v-divider vertical class="ml-5 mr-5"></v-divider>
					{{/*Bot name*/}}
					<v-toolbar-title
						v-if="checkPermit('show_kw_active_bot_title')"
						>{{ active_bot_title }}</v-toolbar-title
					>
				</div>

				<v-btn icon @click.stop="drawer = !drawer" color="primary">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</div>
			{{/*mobile header*/}}

			<div class="menu-mob-body d-flex flex-column h-100-vh">
				<v-select
					:value="bot_settings.active_id"
					:items="botsSelect"
					class="mx-4 mt-4 flex-grow-0"
					:label="$t('modules.dialog.texts.settings.active_bot')"
					hide-details
					filled
					dense
					@change="setActiveBot($event)"
				></v-select>

				<!-- <v-divider></v-divider> -->

				<v-list>
					<v-list-item-group
						v-model="group"
						active-class="menu-item__active"
					>
						<template v-for="(item, i) in full_menu_items">
							<v-list-item
								:key="i"
								v-if="item.show && checkModuleOrTabPermit(item)"
							>
								<!-- <v-list-item-title class="menu-item" v-on:click="changeModule(item.module)">
                                    <div>
                                        <v-icon color="greyBlueLight" v-if="item.icon">{{item.icon}}</v-icon>
                                    </div>
                                    <span>{{ item.title }}</span>
                                </v-list-item-title> -->

								<router-link
									:to="`/${item.route}`"
									v-slot="{ navigate, href }"
									custom
								>
									<v-list-item-title
										class="menu-item"
										role="link"
										:href="href"
										@keypress.enter="navigate"
										@click="navigate"
									>
										<div>
											<v-icon
												color="greyBlueLight"
												v-if="item.icon"
												>{{ item.icon }}</v-icon
											>
										</div>

										<span>{{ item.title }}</span>
                                        
										<div
											class="notification"
                                            v-if="item.module === 'OperatorPanel' && isNotRead()"
										>
											<v-icon small color="#5684eb"
												>mdi-bell</v-icon
											>
										</div>
									</v-list-item-title>
								</router-link>
							</v-list-item>
						</template>
					</v-list-item-group>
				</v-list>

				<v-chip class="align-self-center mb-5 mt-auto menu--version">
					v{{ version }}
				</v-chip>
			</div>

			<template v-slot:append v-if="$vuetify.breakpoint.smAndDown">
				{{/*mobile footer*/}}
				<div class="menu-mob-footer">
					<v-list>
						<v-list-item v-on:click="logout">
							<v-list-item-title class="menu-item">
								<div>
									<v-icon color="greyBlueLight"
										>$iconlogout</v-icon
									>
								</div>
								<span>{{ $t('header.logout') }}</span>
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</div>
			</template>
		</v-navigation-drawer>
		{{/*Menu list*/}}

		{{/*modules*/}}
		<!-- <v-sheet class="h-100-percent app-wrapper"  style="background-color: #F5F6FA;">

            {{/*Подключение модуля по имени. Имя можно задавать динамически*/}}
            {{/*AppDialog AppSettings AppCustom*/}}
            <template v-if="checkComponentShowPermit">
                <component v-if="modules_components[current_module]" v-bind:is="modules_components[current_module]" />
            </template>
            <template v-else>
                <div class="h-100-percent" style="background-color: #fff; text-align: center; padding-top: 50px;">
                    {{$t('app.access_denied')}}
                </div>
            </template>
        </v-sheet> -->

		<router-view v-if="dataReady"></router-view>
	</div>
</template>

<script>
import Vue from 'vue';
import { extra_modules_list } from '@/modules/custom_modules_list';
import Dialog from '@/views/Dialog/Dialog.vue';
import Settings from '@/views/Settings/Settings.vue';
// import AxiosPreloaderAndResponse from '../components/AxiosPreloaderAndResponse.vue';
import { mapGetters } from 'vuex';
import UserService from '@/services/user.service';
import { version } from './../../../package';
import axios from "axios";

const userService = new UserService();

//Базовые компоненты
let components = {
	AppDialog: Dialog,
	AppSettings: Settings,
	// AppCustom: Custom,
	// AppBroadcast: Broadcast,
	// appAxiosPreloaderAndResponse: AxiosPreloaderAndResponse,
};

//Базовый объект названий компонентов для рендеринга
let modules_components = {
	dialog: 'AppDialog',
	settings: 'AppSettings',
	// broadcast: 'AppBroadcast',
	// custom: 'AppCustom'
};

let modules_tabs_items = {};

//Подключение компонентов из модулей
if (extra_modules_list) {
	//Цикл по модулям
	for (let module of extra_modules_list) {
		if (module && module.active && module.component_name) {
			try {
				//Поиск файла настроек
				let obj = require('@/modules/' +
					module.component_name +
					'/kwizbot_settings.js');
				if (obj && obj.default && obj.default.module_settings) {
					//Получение объекта настроек

					//Данные по табам в отдельный объект
					let module_settings = obj.default.module_settings;
					if (module_settings.tabs_items) {
						for (let module_name in module_settings.tabs_items) {
							if (
								module_name &&
								module_settings.tabs_items[module_name] &&
								module_settings.tabs_items[module_name]
									.tabs_items
							) {
								modules_tabs_items[module_name] =
									module_settings.tabs_items[
										module_name
									].tabs_items;
							}
						}
					}

					//Если есть компоненты
					// if(module_settings.components && module_settings.components.length > 0){
					//     //Цикл по списку компонентов и подключение их
					//     for(let component_obj of module_settings.components){
					//         if(component_obj && component_obj.name && component_obj.path){

					//             let component = require('./components/Modules/Extra/' + module.component_name + '/' + component_obj.path)

					//             if(component && component.default) {
					//                 //Добавление компонентов
					//                 components[component_obj.name] = component.default
					//                 //Добавление компонентов в объект компонентов для рендера}
					//                 modules_components[component_obj.name] = component_obj.name
					//             }
					//         }
					//     }
					// }
				}
			} catch (e) {
				//console.log(e);
			}
		}
	}
}

export default {
	name: 'App',

	components,

	data: () => ({
		full_menu_items: [],
		version: null,
		modules_components,
		modules_tabs_items,
		drawer: false,
		group: null,
		renderExtension: true,
		userInfo: {
			avatar_img: '',
		},
		tab: null,
		dataReady: false,
	}),

	mounted: async function () {
		await this.$store.dispatch('loadData');

		//Формирование меню
		// this.updateMenu();
		this.checkLogin();

		this.setCurrentModule(this.$route);

		this.version = version;

		this.dataReady = true;
	},

	methods: {
    isNotRead() {
      if (this.opIsNotification) {
        return true;
      }

      if (!this.opChatRoomGroupsNew) {
        return false;
      }

      return this.opChatRoomGroupsNew.filter(g => g.dialogs.find(
          (d) => d.messages.filter((dm) => !dm.message_read).length
      ))?.length;
    },

    async setActiveBot(id) {
      this.$store.dispatch('updateAjaxDialog', [true, true]);

			this.$store.commit('SET_BOT_SETTING', {
				field: 'active_id',
				value: id,
			});


			let url = this.engine_settings.active_url;
			let request = {
				id: id,
			};
			let token = Vue.$cookies.get('token_e');


			axios
				.post(url + '/kw/activate_bot/', request, {
					headers: {
						'X-CSRF-TOKEN': token,
					},
				})
				.then(
					async (response) => {

						if (
							response.data &&
							response.data.status &&
							response.data.status === 'success'
						) {
              this.$store.dispatch('axiosGetBotsAndLanguages');
						}
					},
					(err) => {
						this.$store.dispatch('updateAjaxDialogError', [
							err,
							this.$t('common.error'),
							this.$t(
								'modules.dialog.texts.settings.cant_activate_bot'
							),
						]);
					}
				);



		},

		checkLogin() {
			if (!Vue.$cookies.get('token_c')) {
				// window.location.href = new URL(window.location.origin + '/login/');
				this.$router.push({ name: 'Login' });
			}
		},

		updateMenu() {
			this.full_menu_items = this.menu_items;
			this.$addExtraModulesSettingsToMainMenu(this);
		},

		changeModule: function (module) {
			//Если сервер не активирован - доступны только настройки
			if (module === 'dialog' && !this.all_bot_data_init.ready) {
				this.$store.dispatch('updateAjaxDialog', [
					true,
					false,
					this.$t(
						'modules.dialog.texts.common.activate_bot_error_title'
					),
					this.$t(
						'modules.dialog.texts.common.activate_bot_error_description'
					),
				]);
				module = 'settings';
				this.$router.push({ name: 'SettingsEngines' });
			}

			this.$store.dispatch('setModule', module);

			//Определение типа таба, пути урла и тайтла документа из объекта роутов
			let tab_type_defined = false;
			if (this.modules_routes_obj && this.modules_routes_obj[module]) {
				for (let tab_type in this.modules_routes_obj[module]) {
					if (tab_type && this.modules_routes_obj[module][tab_type]) {
						let tab = this.modules_routes_obj[module][tab_type];

						//Если у таба есть урл и тайтл
						if (tab.title && tab.path) {
							//установка тайтла документа
							document.title = tab.title;
							//Смена урла в строке браузера
							// window.history.pushState(module, "title", '/'+tab.path);
						}
						tab_type_defined = true;
						// this.$store.dispatch('setTabType', tab_type);
					}
					break;
				}
			}

			//Если тип таба не был определен в модуле - получение первого доступного типа для текущего модуля
			if (
				!tab_type_defined &&
				this.current_module_tab_types &&
				this.current_module_tab_types[0]
			) {
				//Установка дефолтных значений
				document.title = 'Kwizbot';
				// window.history.pushState(module, "title", '/');
				// this.$store.dispatch('setTabType', this.current_module_tab_types[0]);
			}
		},

		checkModuleOrTabPermit: function () {
			// if(item && item.permits && item.permits.show){
			//     return this.checkPermit(item.permits.show)
			// }

			return true;
		},

		checkPermit: function () {
			return true;
		},

		logout: function () {
			userService.signOut().then(() => {
				Vue.$cookies.remove('token_e');
				Vue.$cookies.remove('token_c');

				// let url = new URL(window.location.origin + '/login/');
				// window.location.href = url;
				this.$router.push({ name: 'Login' });
			});
		},

		forceRerenderExtension() {
			this.renderExtension = false;

			this.$nextTick().then(() => {
				this.renderExtension = true;
			});
		},

		setCurrentModule(route) {
			const moduleName = route.fullPath.split('/')[1];

			if (!moduleName) return;

			this.changeModule(moduleName);

			// this.$store.dispatch("setModule", moduleName);
		},
	},

	computed: {
		...mapGetters([
			'menu_items',
			'current_module',
			'current_module_obj',
			'current_tab_type',
			'engine_settings',
			'bot_settings',
			'active_bot_title',
			'all_bot_data_init',
			'modules_routes_obj',
			'bots',
			'opChatRoomGroupsNew',
			'opIsNotification',
		]),

		botsSelect() {
			return this.bots.map((b) => {
				return {
					text: b.name,
					value: b.id,
				};
			});
		},

		activeBot() {

			if (!this.bot_settings.active_id && this.bots && this.bots.length > 0) {
				return this.bots.find((b) => b.active).id;
			}

			return +this.bot_settings.active_id;
		},

		currentUser() {
			return this.$store.state.kwizbot.user;
		},

		checkCurrentTabIsChat() {
			const isMob = this.$vuetify.breakpoint.smAndDown;
			const isChat = this.current_tab_type === 'chats';

			return isMob && isChat;
		},

		checkComponentShowPermit: function () {
			// if(this.modules_tabs_items && this.current_module && this.modules_tabs_items[this.current_module]){
			//     let module_tabs = this.modules_tabs_items[this.current_module]
			//     for(let tab of module_tabs){
			//         if(tab && tab.type && tab.type === this.current_tab_type && tab.permits && tab.permits.show){
			//             return this.checkPermit(tab.permits.show)
			//         }
			//     }

			// }

			return true;
		},

		//Массив всех текущих типов табов модуля
		current_module_tab_types: function () {
			let types = [];

			if (this.current_module_obj && this.current_module_obj.tabs_items) {
				for (let tab_item of this.current_module_obj.tabs_items) {
					if (tab_item && tab_item.type) {
						types.push(tab_item.type);
					}
				}
			}

			return types;
		},

		extension: function () {
			let extension = {
				show: false,
				tabs_items: [],
			};

			if (this.current_module_obj) {
				if (
					this.current_module_obj &&
					this.current_module_obj.use_tabs
				) {
					extension.show = true;
				}
				if (
					this.current_module_obj &&
					this.current_module_obj.tabs_items
				) {
					extension.tabs_items = this.current_module_obj.tabs_items;
					userService.filterTabsByExtention(
						this.current_module,
						extension.tabs_items
					);
				}
			}

			return extension;
		},

		// tab: {
		//     // геттер:
		//     get: function () {
		//         return this.current_tab_type
		//     },
		//     // сеттер:
		//     set: function (val) {

		//         //Устанавливается значение только если оно есть в списке табов
		//         if(this.current_module_tab_types.includes(val)){

		//             //поиск урла и тайтла для переключения
		//             if(this.current_module && this.modules_routes_obj && this.modules_routes_obj[this.current_module]){
		//                 for(let tab_type in this.modules_routes_obj[this.current_module]){
		//                     if(tab_type && this.modules_routes_obj[this.current_module][tab_type]){
		//                         //Таб совпадает с указанным в сеттере
		//                         if(tab_type === val){
		//                             let tab = this.modules_routes_obj[this.current_module][tab_type]

		//                             //если указан тайтл документа и путь урла
		//                             if(tab.title && tab.path){
		//                                 //установка тайтла документа
		//                                 document.title = tab.title
		//                                 //установка урла в строке браузера
		//                                 window.history.pushState(module, "title", '/'+tab.path);
		//                             }
		//                             break
		//                         }
		//                     }
		//                 }
		//             }

		//             //Установка типа таба
		//             this.$store.dispatch('setTabType', val);
		//         }

		//     }
		// }
	},

	watch: {
		current_module: function () {
			//сброс таба при переключении модуля
			this.tab = this.$route.path || null;
		},

		group() {
			this.drawer = false;
		},

		currentUser(user) {
			this.updateMenu();
			userService.setData(user, this.engine_settings, Vue.$cookies);

			this.full_menu_items = userService.filterMenu(this.full_menu_items);
			this.forceRerenderExtension();

			this.userInfo = user;
		},

		$route: 'setCurrentModule',
	},
};
</script>

<style lang="scss">
.app-wrapper {
	background-color: var(--v-gray3-base) !important;
	flex-grow: 1;
}
.overflow-hidden {
	overflow: hidden !important;
}
.h-100-vh {
	height: 100vh;
}
.h-100-percent {
	height: 100%;
}
.w-100-percent {
	width: 100%;
}
.menu-item {
	min-height: 48px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	white-space: normal !important;

	// & > div{
	//     /*height: 100%;*/
	//     /*width: 25px;*/
	//     /*min-width: 25px;*/
	//     /*display: flex;*/
	//     /*align-items: center;*/
	//     /*justify-content: center;*/

	//     /*& > svg{*/
	//     /*    width: 100%;*/
	//     /*}*/
	// }

	& > span {
		display: block;
		margin-left: 20px;
		color: var(--v-greyPurple-base);
		font-size: 14px;

		@media screen and (max-width: 959px) {
			font-size: 13px;
		}
	}
}

.menu-item:hover {
	color: #5567ff;
}
#no-background-hover::before {
	background-color: transparent !important;
}
.theme--light.v-divider {
	border-color: #E5E5E5 !important;
}

/* common Classes */
.box-shadow-theme {
	box-shadow: 0px 5px 15px rgba(77, 76, 172, 0.12) !important;
}
.v-tooltip__content {
	background: #050038;
	opacity: 0.8;
	box-shadow: 0px 5px 15px rgba(77, 76, 172, 0.12);
	border-radius: 4px;
}

/*** Header ***/
.header-tabs {
	@media screen and (max-width: 959px) {
		.v-slide-group__prev--disabled,
		.v-slide-group__next--disabled {
			display: none !important;
		}

		width: calc(100% - 25px);
	}
}
/*** Header ***/

.menu--version{
	min-height: 32px;
}

/*** mobile menu ***/
.main-menu-drawer {
	@media screen and (min-width: 960px) {
		.menu-mob-body {
			overflow-y: scroll;
		}
	}
	
	@media screen and (max-width: 959px) {
		width: 100% !important;

		.menu-mob-body {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			overflow-x: hidden;
			overflow-y: auto;
		}

		.menu-mob-footer {
			margin-top: auto;
		}
	}
}
.menu-mob-header {
	height: 60px;
	width: 100%;
	box-shadow: 0px 5px 15px rgba(77, 76, 172, 0.12);
	background-color: #fff;
	padding: 0 10px 0 15px;
}

.app-wrapper {
	& > div > div > div {
		height: 100%;
	}
}

.box-shadow__ischat {
	position: relative;
	z-index: 2;
}
.header-op-title {
	font-weight: 500;
	font-size: 14px;
	color: var(--v-secondary);
	flex: 1;
}
.notification {
	max-width: 21px !important;
	display: flex !important;
	justify-content: flex-start !important;
    margin-left: auto;

	> i {
		animation: ring 1s ease-in-out infinite;

		@keyframes ring {
			0%,
			60% {
				transform: rotate(0);
			}
			20%,
			40% {
				transform: rotate(8deg);
			}
			30%,
			50% {
				transform: rotate(-8deg);
			}
		}
	}
}
</style>

<style lang="scss" scoped>
.main-app-header {
	overflow: hidden;
}
.menu-item__active {
	::v-deep .greyBlueLight--text {
		color: var(--v-primary-base) !important;
	}

	span {
		color: var(--v-primary-base);
	}
}
</style>
